<!-- 載入中 -->
<app-loading-spinner *ngIf="isLoading && !error"></app-loading-spinner>

<!-- 錯誤 -->
<app-error-alert *ngIf="error && !isLoading"></app-error-alert>

<main *ngIf="!error && !isLoading">

  <table class="table" *ngIf="curWidth > 576; else RWD" >
      <thead>
        <tr>
          <th>身分別</th>
          <th>帳號資訊</th>
          <th>營運操作</th>
          <th>推廣控管</th>
          <!-- <th>帳務報表</th> -->
          <th>代理控管</th>
          <th>會員控管</th>
          <th>系統管理</th>
          <th>權限編輯</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let p of permission">
          <td>{{ p.role | identity }}</td>
          <td>{{ p.roleAuth | bitWise:1 }}</td>
          <td>{{ p.roleAuth | bitWise:2 }}</td>
          <td>{{ p.roleAuth | bitWise:4 }}</td>
          <!-- <td>{{ p.roleAuth | bitWise:8 }}</td> -->
          <td>{{ p.roleAuth | bitWise:16 }}</td>
          <td>{{ p.roleAuth | bitWise:32 }}</td>
          <td>{{ p.roleAuth | bitWise:64 }}</td>
          <td class="form-link" (click)="openModal(p.role, p.roleAuth)">編輯</td>
        </tr>
      </tbody>
  </table>

  <ng-template #RWD>
    <table class="table">
      <thead>
        <th></th>
        <th *ngFor="let p of permission">
          {{ p.role | identity }}
        </th>
      </thead>

      <tbody>
        <tr>
          <td>帳號資訊</td>
          <td *ngFor="let p of permission">
            {{ p.roleAuth | bitWise:1 }}
          </td>
        </tr>
        <tr>
          <td>營運操作</td>
          <td *ngFor="let p of permission">
            {{ p.roleAuth | bitWise:2 }}
          </td>
        </tr>
        <tr>
          <td>推廣控管</td>
          <td *ngFor="let p of permission">
            {{ p.roleAuth | bitWise:4 }}
          </td>
        </tr>
        <tr>
        <!-- <tr>
          <td>帳務報表</td>
          <td *ngFor="let p of permission">
              {{ p.roleAuth | bitWise:8 }}
          </td>
        </tr> -->
        <tr>
          <td>代理控管</td>
          <td *ngFor="let p of permission">
            {{ p.roleAuth | bitWise:16 }}
          </td>
        </tr>
        <tr>
          <td>會員控管</td>
          <td *ngFor="let p of permission">
            {{ p.roleAuth | bitWise:32 }}
          </td>
        </tr>
        <tr>
          <td>系統管理</td>
          <td *ngFor="let p of permission">
            {{ p.roleAuth | bitWise:64 }}
          </td>
        </tr>
        <tr>
          <td>權限編輯</td>
          <td
          class="form-link"
          *ngFor="let p of permission"
          (click)="openModal(p.role, p.roleAuth)">
            編輯
          </td>
        </tr>
      </tbody>
    </table>
  </ng-template>

</main>

