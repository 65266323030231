import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

// Service
import { ReloadService } from 'src/app/shared/services/reload.service';


// Enviroment
import { environment } from 'src/environments/environment';

// Service
import { ParamsService } from '../../params.service';

@Component({
  selector: 'app-sell-modify-modal',
  templateUrl: './sell-modify-modal.component.html',
  styleUrls: ['./sell-modify-modal.component.scss']
})
export class SellModifyModalComponent implements OnInit {
  @ViewChild('f', { static: false })
  editForm!: NgForm;
  // button 狀態管理
  state: string = '確認';
  isStoring: boolean = false;
  editChannel = '';

  constructor(
    public modalRef: MdbModalRef<SellModifyModalComponent>,
    private http: HttpClient,
    private paramsService: ParamsService,
    private reloadService: ReloadService) { }

  ngOnInit(): void {
  }

  onSubmit() {
    const body = {
      seq: this.paramsService.getParams(),
      name: this.editForm.value.editChannel
    }

    // Showing spinner & State change
    this.isStoring = true;
    this.state = '儲存中';

    this.http
      .put(`${environment.SellsServer}/api/Channels`, body)
      .subscribe({
        next: (response: any) => {
          setTimeout(() => {
            this.isStoring = false;
            this.state = '編輯成功';

            setTimeout(() => {
              this.modalRef.close();
              this.reloadService.redirectTo('sell-manage/sell-setting');
            }, 1500)

          }, 1500)
        },
        error: (err: any) => {
          setTimeout(() => {
            this.isStoring = false;
            this.state = '編輯失敗';
            console.log(err);

            setTimeout(() => {
              this.editChannel = '';
              this.state = '確認';
            }, 2000)

          }, 1500)
        }
      })

  }
}
