<app-error-alert *ngIf="error"></app-error-alert>


<!-- 日期搜索 -->
<ng-container *ngIf="!error">
  <form
  [formGroup]="searchForm"
  (ngSubmit)="onSubmit()"
  class="form"
  mat-raised-button #tooltip="matTooltip"
  [matTooltip]="tip"
  aria-tooltip="Button that displays and hides a tooltip triggered by other buttons">

  <div>
    <input
    formControlName="start"
    type="date"
    min="2023-01-01"
    max="{{ date | date:'yyyy-MM-dd' }}"
    required>

    <span class="wave">&nbsp;～&nbsp;</span>

    <input
    formControlName="end"
    type="date"
    min="2023-01-01"
    max="{{ date | date:'yyyy-MM-dd' }}"
    required>
  </div>

  <div>
    <button
    type="submit"
    class="btn btn--search"
    [disabled]="!isRangeValid || searchForm.invalid || !curAuth.canManipulate">查詢</button>
  </div>

</form>

<div
class="pointer tip"
(click)="tooltip.toggle()"
aria-label="Show/Hide tooltip on the button at the end of this section">
<i class="far fa-question-circle fa-2x mt-2"></i>
</div>

</ng-container>
