<nav>
    <div class="wrap">

      <!-- Desk User Area -->
      <div *ngIf="curWidth >= 768" class="user">
        <a>
          <img src="assets/image/Diamondonline_personalbusiness_fudanlogo_200x200.png" alt="user__picture" class="user__picture">
        </a>
        <!-- <a title="尚未開放此功能">
          <img src="assets/image/Diamondonline_personalbusiness_file_80x80.png" alt="user__file" class="user__file">
        </a> -->
        <p class="user__name">使用者ID：{{ userData?.dAccount ? userData?.dAccount : '無' }}</p>
        <a>
          <img
          src="assets/image/Diamondonline_personalbusiness_reset_80x80.png"
          alt="user__reset"
          class="user__reset"
          (click)="getRouter()">
        </a>
      </div>

      <!-- RWD User(Function) Area -->
      <div *ngIf="curWidth < 768" class="userRWD">

        <div class="userRWD__switch">
          <a (click)="toggleDarkTheme()">
            <img class="userRWD__switch--mode" src="assets/image/Diamondonline_personalbusiness_moon_80x80.png" alt="日夜間模式">
          </a>
          <span class="userRWD__switch--text">點擊切換為夜間模式</span>
        </div>

        <div class="userRWD__logout">
          <a routerLink="/" (click)="onLogout()" class="userRWD__logout--icon" title="點此登出">
            <img class="arrow" src="assets/image/Diamondonline_personalbusiness_out_80x80.png" alt="logout">
            <span class="userRWD__logout--text">登出</span>
          </a>
        </div>

      </div>


      <ul class="sidebar">
        <!-- 帳號資訊 -->
        <li class="sidebar__menu" routerLink="account"><span class="sidebar__decorator"></span><a
            class="btn btn--menu" routerLinkActive="active" (click)="closeBack()">帳號資訊</a>
        </li>

        <!-- 營運控管 -->
        <li class="sidebar__menu" [hidden]="!permissionData['business-manage']" (click)="basicCollapse.toggle(); linkCollapse.hide()"
          [attr.aria-expanded]="!basicCollapse.collapsed" aria-controls="BusinessManageCollapse">
          <span class="sidebar__decorator"></span>
          <a class="btn btn--menu" [ngClass]="{'active': url.includes('business-manage')}">營運操作
            <img class="sidebar__img" src="assets/image/Diamondonline_personalbusiness_list2_80x80.png"
              alt="dropdown">
          </a>
        </li>

        <li class="sidebar__menu" id="BusinessManageCollapse" mdbCollapse #basicCollapse="mdbCollapse">
          <a class="btn btn--dropdown" routerLink="business-manage/operation-detail"
            routerLinkActive="drop-active" (click)="closeBack()">營運數據</a>
          <a class="btn btn--dropdown" routerLink="business-manage/finance-detail"
            routerLinkActive="drop-active" (click)="closeBack()">帳務資訊</a>
          <a class="btn btn--dropdown" routerLink="business-manage/ga-setting"
            routerLinkActive="drop-active" (click)="closeBack()">總代設置</a>
          <a class="btn btn--dropdown" routerLink="business-manage/permission-setting"
            routerLinkActive="drop-active" (click)="closeBack()">權限設置</a>
        </li>

        <!-- 推廣控管 -->
        <li class="sidebar__menu" routerLink="sell-manage" [hidden]="!permissionData['sell-manage']"><span class="sidebar__decorator"></span><a
            class="btn btn--menu" routerLinkActive="active" (click)="closeBack()">推廣控管</a>
        </li>

        <!-- 帳務報表 -->
        <!-- <li class="sidebar__menu" routerLink="finance-report" [hidden]="!permissionData['finance-report']"><span class="sidebar__decorator"></span><a
            class="btn btn--menu" routerLinkActive="active" (click)="closeBack()">帳務報表</a>
        </li> -->

        <!-- 代理控管 -->
        <li class="sidebar__menu" routerLink="agent-manage" [hidden]="!permissionData['agent-manage']"><span class="sidebar__decorator"></span><a
            class="btn btn--menu" routerLinkActive="active" (click)="closeBack()">代理控管</a>
        </li>

        <!-- 會員控管 -->
        <li class="sidebar__menu" routerLink="member-manage" [hidden]="!permissionData['member-manage']"><span class="sidebar__decorator"></span><a
            class="btn btn--menu" routerLinkActive="active" (click)="closeBack()">會員控管</a>
        </li>

        <!-- 系統管理 -->
        <li class="sidebar__menu" [hidden]="!permissionData['system-manage']" (click)="linkCollapse.toggle(); basicCollapse.hide()"
          [attr.aria-expanded]="!linkCollapse.collapsed" aria-controls="SystemManageCollapse">
          <span class="sidebar__decorator"></span>
          <a class="btn btn--menu" [ngClass]="{'active': url.includes('system-manage')}">
            系統管理
            <img class="sidebar__img" src="assets/image/Diamondonline_personalbusiness_list2_80x80.png"
              alt="dropdown">
          </a>
        </li>

        <li class="sidebar__menu mb-5" id="SystemManageCollapse" mdbCollapse #linkCollapse="mdbCollapse">
          <!-- <a class="btn btn--dropdown" routerLink="system-manage/backstage-record"
            routerLinkActive="drop-active" (click)="closeBack()">後台操作紀錄</a> -->
          <a class="btn btn--dropdown" routerLink="system-manage/backstage-manage"
            routerLinkActive="drop-active" (click)="closeBack()">後台操作管理</a>
        </li>
      </ul>

    </div>
  </nav>

