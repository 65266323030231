import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'convertISO'
})

export class ConvertISOPipe implements PipeTransform {

  transform(value: any) {
    // 轉換 ISO 為當地時間
    if (!value) {
      return '無'
    } else {
      return new Date(value).toLocaleDateString();
    }
  }
}
