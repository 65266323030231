import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from "@angular/common/http";

import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-backstage-record',
  templateUrl: './backstage-record.component.html',
  styleUrls: ['./backstage-record.component.scss']
})
export class BackstageRecordComponent implements OnInit {
  // 日期範圍檢查
  isRangeInvalid = true;

  // 取得當下時間，限定 Data Picker 的最大日期為當日
  date = new Date;

  // FormGroup
  searchForm!: FormGroup;

  // Fake Data
  users = [
    {
      time: '2022-01-01 14:43:31',
      account: '222222',
      nickName: 'BlaBlaOOO',
      ip: '1.3.1.0',
      sort: '後台-後台-後台登入',
      content: '登入系統',
    },
    {
      time: '2022-01-01 14:43:31',
      account: '222222',
      nickName: 'BlaBlaOOO',
      ip: '1.3.1.0',
      sort: '後台-後台-後台登入',
      content: '登入系統',
    }
  ]

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.searchForm = new FormGroup({
      'start': new FormControl(null, Validators.required),
      'end': new FormControl(null, Validators.required)
    })

    this.searchForm.valueChanges.subscribe(
      (value: any) => {
        if(value.start <= value.end) {
          this.isRangeInvalid = false;
        } else {
          this.isRangeInvalid = true;
        }
      }
    )
  }

  onSubmit() {
    // 將日期轉為 ISO 格式
    let start = new Date(this.searchForm.value.start).toISOString();
    let end = new Date(this.searchForm.value.end).toISOString();

    // 打 API
    this.http.get(`${environment.SellsServer}/api/System/from/${start}/to/${end}`).subscribe(
      responseData => {
        console.log(`Success, ${responseData}`);
      }, error => {
        console.log(error);
      }
    )
  }
}
