import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-finance-report',
  templateUrl: './finance-report.component.html',
  styleUrls: ['./finance-report.component.scss']
})
export class FinanceReportComponent implements OnInit {
  // ngForm
  title: string | null = null;
  @ViewChild('f', { static: false })
  searchForm!: NgForm;

  searchInput = '';
  users = [
    {
      account: '123456',
      username: '名稱可以六個字',
      identity: '總代',
      salesForecast: 10000,
      receivedCash: 5000,
      achievedSales: 1500,
      feeSplitting: 2,
      achievedRate: 1.36,
      feeSplittingNumber: -1970
    }
    ,
    {
      account: '7654321',
      username: '名稱可以七個字吧',
      identity: '代理',
      salesForecast: 8000,
      receivedCash: 3000,
      achievedSales: 500,
      feeSplitting: 1,
      achievedRate: 1,
      feeSplittingNumber: 67
    },
  ]

  constructor() { }

  ngOnInit(): void {
  }

  searchMonth() {
    console.log(this.searchInput);
  }
}
