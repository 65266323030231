import { Component, HostListener, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';

// Module
import { CurrentAuth } from 'src/app/models/currentAuth.model';
import { Member } from 'src/app/models/member';

// Modal
import { MailMModalComponent } from './mail-modal/mail-modal.component';
import { SendMDcoinModalComponent } from './send-mdcoin-modal/send-mdcoin-modal.component';

// Service
import { CheckboxService } from 'src/app/shared/services/checkbox.service';
import { CurAuthService } from 'src/app/shared/services/curAuth.service';
import { MemberManageService } from './member-manage.service';

// Animation
import { expandOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-member-manage',
  templateUrl: './member-manage.component.html',
  styleUrls: ['./member-manage.component.scss'],
  animations: [
    expandOnEnterAnimation()
  ]
})
export class MemberManageComponent implements OnInit {
  modalRef: MdbModalRef<MailMModalComponent> | null = null;
  isLoading: boolean = false; // 載入狀態
  error: boolean = false; // 錯誤
  curWidth: number = 0; // 視窗寬度
  p: number = 1; // 目前分頁
  items: number = 10;  // 單頁項目數量
  tipContent: string = '查詢選擇日期內的體驗金派發／儲值金額資訊，預設顯示當月資訊。'; // 提示字元
  ghost: number = 0; // 幽靈人口數

  // 權限確認
  curAuth: CurrentAuth = {
    canLogin: true,
    canManipulate: true,
    canSetChannel: true,
    canSendEmail: true,
    canSendCoin: true
  };

  searchInput: string = ''; // 搜尋框的值

  members: Member[] = []; // member array
  memberId: number[] = []; // 被勾選的 dUid
  selected: string = ''; // 選定之操作
  none: boolean = false; // false: '全選'、true: '取消'

  constructor(
    private modalService: MdbModalService,
    private checkboxService: CheckboxService,
    private currentAuthService: CurAuthService,
    private memberManageService: MemberManageService
  ) {}

  async ngOnInit(): Promise<void> {
    // 依據 width 決定渲染電腦版 or 手機板
    this.curWidth = window.innerWidth;

    await this.getCurAuth();
    this.getMembers('');
  }

  // 監聽 window 寬度
  @HostListener('window:resize', ['$event']) onResize() {
    this.curWidth = window.innerWidth;
  }

  openMailModal() {
    this.modalRef = this.modalService.open(MailMModalComponent, {
      modalClass: 'modal-dialog-centered',
    });
  }

  openSendMDcoinModal() {
    this.modalRef = this.modalService.open(SendMDcoinModalComponent, {
      modalClass: 'modal-dialog-centered',
    });
    // console.log(this.memberId);
  }

  /**
   * 取得目前角色權限
   */
  async getCurAuth() {
    this.isLoading = true;

    await this.currentAuthService.onGetCurAuth()
      .then((response: any) => {
        this.curAuth = response;
        this.isLoading = false;
      })
      .catch(() => {
        this.error = true;
      })
  }

  /**
   * 取得會員列表
   */
  getMembers(date: any) {
    // {start?: xxx, end?: xxx}

    // 載入中
    this.isLoading = true;

    // 如果在選單狀態中重新查詢，由於 select、checkbox 都沒有偵測到 change，則 memberId、option 皆會留存造成 Bug，需清空處理
    this.memberId = [];
    this.selected = '';


    this.memberManageService
    .getMembers(date.start, date.end)
    .subscribe({
      next: (response: any) => {
        // 成功，停止載入並刷新資料
        setTimeout(() => {
          this.isLoading = false;
          this.members = response;
          this.ghost = this.members.filter(i => i.isGhost === true).length
        }, 300)
      },
      error: (err: any) => {
        // 失敗，停止載入並顯示錯誤訊息
        setTimeout(() => {
          this.isLoading = false;
          this.error = true;
          console.log(err);
        }, 1000)
      }
    })
  }

  // checkbox 勾選／取消勾選
  toggleMemberId(event: Event) {
    let id = parseInt((<HTMLInputElement>event.target).value, 10);
    this.checkboxService.isChecked(this.memberId, id);

    // console.log(this.memberId);

    // 將 memberId 輸出至 service
    this.checkboxService.sendMemberIdList(this.memberId);
  }

  // select 的選取項目（會依據項目顯示可供勾選的 checkbox）
  selectAction(event: Event) {
    // 切換 option 時清空原 option 已勾選的 memberId，否則遺留的 Id 會造成 Bug
    this.memberId = [];
    this.none = false;
    this.selected = (<HTMLInputElement>event.target).value;
  }

  checkAll() {
    // display '取消'
    this.none = true;
    this.checkboxService.allMembers(this.members, this.memberId, this.selected, this.p, this.items);
    // console.log(this.memberId);

    // 將 memberId 輸出至 service
    this.checkboxService.sendMemberIdList(this.memberId);
  }

  cancelAll() {
    // display '全選'
    this.none = false;
    this.memberId = [];
  }

  /**
   * 變更搜尋關鍵字時清除原已選取項目，並強制返回第一頁
   * @param event
   */
  onSearchChange() {
    this.memberId = [];
    this.p = 1;
  }

  /**
   * 清除搜尋關鍵字
   */
  clear() {
    this.searchInput = '';
  }
}
