import { HttpInterceptor, HttpRequest, HttpHandler } from "@angular/common/http";

export class AuthInterceptorService implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // get token
        const token = localStorage.getItem('token');

        // set Header
        req = req.clone({
            setHeaders: {
              'Authorization': `bearer ${token}`,
              'Content-Type': 'application/json;charset=UTF-8',
              "Access-Control-Allow-Origin": "*", }
        })

        return next.handle(req);
    }
}
