import { Component, OnInit } from '@angular/core';

// Environment
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-operation-detail',
  templateUrl: './operation-detail.component.html',
  styleUrls: ['./operation-detail.component.scss']
})
export class OperationDetailComponent implements OnInit {
  url: string = `${environment.OperationDetail}`

  constructor() { }

  ngOnInit(): void {
  }

}
