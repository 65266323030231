<div class="wrap">

  <div class="modal-header">
    <h2 id="exampleModalLabel" class="modal-title">
      代理確認信件</h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
  </div>

  <div class="modal-body">

      <!-- ul li ol 顯示會有問題，直接用 p 省事 -->
      <p>
        1. 點擊寄送，將會發送認證連結至鑽很大平台信箱<br>
        2. 請確認該會員為「高級會員」，否則無法發送認證信<br>
        3. 連結有效時間為 2 小時，寄送後 2 小時內不得再次發送
      </p>

      <button
      class="btn btn--request"
      type="submit"
      [disabled]="state !== '寄送'"
      (click)="onSubmit()">
      <span
      *ngIf="isStoring"
      class="spinner-border spinner-border-sm">
      </span>
        {{ state }}
      </button>

  </div>

</div>
