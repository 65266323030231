<div class="wrap">

  <div class="modal-header">
    <h2 id="exampleModalLabel" class="modal-title">
      會員確認信件</h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
  </div>

  <div class="modal-body">

    <p>
      1. 點擊寄送，將會發送認證連結至鑽很大平台信箱<br>
      2. 連結有效時間為 2 小時，寄送後 2 小時內不得再次發送
    </p>


    <button
      class="btn btn--request"
      type="submit"
      [disabled]="state !== '寄送'"
      (click)="onSubmit()">
      <span
      *ngIf="isStoring"
      class="spinner-border spinner-border-sm">
      </span>
        {{ state }}
      </button>

  </div>

</div>
