import { Component, HostListener, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { HttpClient } from "@angular/common/http";

// Modal
import { GoalSettingModalComponent } from './goal-setting-modal/goal-setting-modal.component';
import { AddBossModalComponent } from './add-boss-modal/add-boss-modal.component';
import { SendGaCoinModalComponent } from './send-ga-coin-modal/send-ga-coin-modal.component';

// Model
import { Ga } from 'src/app/models/ga';

// Service
import { CheckboxService } from 'src/app/shared/services/checkbox.service';

// Environment
import { environment } from 'src/environments/environment';

// Animation
import { expandOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-ga-setting',
  templateUrl: './ga-setting.component.html',
  styleUrls: ['./ga-setting.component.scss'],
  animations: [
    expandOnEnterAnimation()
  ]
})
export class GaSettingComponent implements OnInit {
  modalRef: MdbModalRef<GoalSettingModalComponent> | null = null;

  isLoading: boolean = false; // 載入狀態
  error: boolean = false; // 錯誤
  curWidth: number = 0; // 視窗寬度
  p: number = 1; // 目前分頁
  items: number = 10;  // 單頁項目數量


  gas: Ga[] = []; // ga array
  gaId: number[] = []; // 被勾選的 ga userId
  none: boolean = false; // false: '全選'、true: '取消'

  constructor(
    private modalService: MdbModalService,
    private http: HttpClient,
    private checkboxService: CheckboxService,
  ) { }

  ngOnInit(): void {
    // 依據 width 決定渲染電腦版 or 手機板
    this.curWidth = window.innerWidth;
    // 載入中
    this.isLoading = true;

    // 取得總代資訊
    this.http
      .get(`${environment.SellsServer}/api/Operations/get/gas`)
      .subscribe({
        next: (response: any) => {
          // 回傳成功，停止載入、刷新資料（這支 API 回傳得比較慢）
          setTimeout(() => {
            this.fetchData(response as Ga[]);
            this.isLoading = false;
          }, 300)
        },
        error: (err: any) => {
          // 回傳失敗，停止載入、顯示 fail
          setTimeout(() => {
            console.log(err);
            this.isLoading = false;
            this.error = true;
          }, 1000)
        }
      })
  }

  // 監聽 window 寬度
  @HostListener('window:resize', ['$event']) onResize() {
    this.curWidth = window.innerWidth;
  }

  openGoalSettingModal() {
    this.modalRef = this.modalService.open(GoalSettingModalComponent, {
      modalClass: 'modal-dialog-scrollable'
    });
  }

  openSendGaCoinModal() {
    this.modalRef = this.modalService.open(SendGaCoinModalComponent, {
      modalClass: 'modal-dialog-centered'
    });
  }


  openAddBossModal() {
    this.modalRef = this.modalService.open(AddBossModalComponent, {
      modalClass: 'modal-dialog-centered'
    });
  }

  // 賦值
  fetchData(data: Ga[]) {
    this.gas = data;
  }

  // checkbox 勾選／取消勾選
  toggleGaId(event: Event) {
    let id = parseInt((<HTMLInputElement>event.target).value, 10);
    this.checkboxService.isChecked(this.gaId, id);

    // 將 gaId 輸出至 service
    this.checkboxService.sendGaIdList(this.gaId);
  }

  checkAll() {
    // display '取消'
    this.none = true;
    this.checkboxService.allGas(this.gas, this.gaId, this.items);

    // 將 gaId 輸出至 service
    this.checkboxService.sendGaIdList(this.gaId);
  }

  cancelAll() {
    // display '全選'
    this.none = false;
    this.gaId = [];
  }

}
