<ul class="sellManage">
  <li class="sellManage__list">
    <a routerLink="sell-result" routerLinkActive="active" class="btn btn--tab sellManage__link">
      推廣成果
    </a>
  </li>
  <li class="sellManage__list">
    <a routerLink="sell-setting" routerLinkActive="active" class="btn btn--tab sellManage__link">
      推廣設定
    </a>
  </li>
</ul>

<router-outlet></router-outlet>
