<div class="wrap">

  <div class="modal-header">

    <h3 id="exampleModalLabel" class="modal-title">
      QR Code掃描</h3>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
  </div>

  <div class="modal-body">

    <qrcode [qrdata]="QrCode" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>

  </div>

</div>
