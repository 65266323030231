<!-- 此帳號資訊 -->
<app-my-agent></app-my-agent>

<!-- 日期搜索 -->
<app-search-date (searchTime)="getMembers($event)" [tip]="tipContent"></app-search-date>

<!-- 載入中 -->
<app-loading-spinner *ngIf="isLoading && !error"></app-loading-spinner>

<!-- 錯誤 -->
<app-error-alert *ngIf="error && !isLoading"></app-error-alert>

<!-- 三分天下功能區 -->
<ng-container *ngIf="members.length && !isLoading && !error">

  <section class="row m-0 d-md-flex justify-content-between align-items-center" [@expandOnEnter]>
    <!-- 選擇操作的選單 -->
    <div class="active col-md-3">

      <select (change)="selectAction($event)">
        <option value="default">*請選擇操作</option>
        <option [disabled]="!curAuth.canSendEmail || !curAuth.canManipulate" value="email">發送確認信件</option>
        <option [disabled]="!curAuth.canSendCoin || !curAuth.canManipulate" value="coin">體驗金／鑽幣分派</option>
      </select>

      <button
      *ngIf="selected === 'email'"
      class="btn btn--request" (click)="openMailModal()"
      [disabled]="!memberId.length">
        確認
      </button>

      <button
      *ngIf="selected === 'coin'"
      class="btn btn--request"
      (click)="openSendMDcoinModal()"
      [disabled]="!memberId.length">
      確認
      </button>

      <ng-container *ngIf="(curWidth < 576) && (!selected || selected === 'default')">
        <div class="take--over"></div>
      </ng-container>
    </div>

    <!-- 分頁 -->
    <pagination-controls
      *ngIf="curWidth > 576 && members.length > items"
      screenReaderPaginationLabel="Pagination"
      screenReaderPageLabel="page"
      screenReaderCurrentLabel="You're on page"
      (pageChange)="p = $event"
      previousLabel="上一頁"
      nextLabel="下一頁"
      class="col-md-3">
    </pagination-controls>

    <!-- 搜尋框 -->
    <div class="searchInput col-md-3 order-md-last order-first">
      <input
          type="text"
          placeholder="輸入暱稱以搜尋..."
          class="form-control rounded"
          [(ngModel)]="searchInput"
          (keyup)="onSearchChange()"
          (mouseup)="onSearchChange()">
        <span class="input-group-text border-0" id="search-addon" type="submit">
          <i *ngIf="!searchInput" class="fas fa-search"></i>
          <i *ngIf="searchInput" class="fas fa-times" (click)="clear()"></i>
        </span>
    </div>
  </section>
</ng-container>

<!-- 有資料、非載入中、無錯誤、Desktop table-->
<main *ngIf="members.length && !isLoading && !error && curWidth > 576; else RWD" [@expandOnEnter]>

  <table class="table table-hover">
    <thead>
      <tr>
        <ng-container *ngIf="!selected || selected === 'default' || searchInput.length">
          <th>勾選</th>
        </ng-container>
        <ng-container *ngIf="(selected === 'email' || selected === 'coin') && !searchInput.length">
          <th>
            <span
              *ngIf="!none && !searchInput.length"
              (click)="checkAll()"
              class="pointer">
              <i class="far fa-check-square"></i>&nbsp;全選
            </span>
            <span
              *ngIf="none && !searchInput.length"
              (click)="cancelAll()"
              class="pointer underline">
            取消&nbsp;({{ memberId.length }})
            </span>
          </th>
        </ng-container>
        <ng-container *ngIf="!searchInput">
          <th>編號</th>
        </ng-container>
        <th>暱稱</th>
        <th>加入日期</th>
        <th>已派發體驗金</th>
        <th>持有鑽幣</th>
        <th>儲值金額</th>
        <th>退出前之儲值金額</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let member of members | filter:searchInput:'dNickname'|
        paginate: {
          itemsPerPage: items,
          currentPage: p
        };
        let i = index">
        <!-- 發信 -->
        <ng-container *ngIf="selected === 'email'">
          <td>
            <input
            *ngIf="!member.joined && !member.isGhost"
            [id]="i"
            type="checkbox"
            [value]="member.dUid"
            [checked]="memberId.includes(member.dUid)? true : false"
            (change)="toggleMemberId($event)">
          </td>
        </ng-container>
        <!-- 發錢 -->
        <ng-container *ngIf="selected === 'coin'">
          <td>
            <input
            *ngIf="member.joined && !member.isGhost"
            [id]="i"
            type="checkbox"
            [value]="member.dUid"
            [checked]="memberId.includes(member.dUid)? true : false"
            (change)="toggleMemberId($event)">
          </td>
        </ng-container>
        <!-- nothing / default -->
        <ng-container *ngIf="(!selected || selected === 'default') && !searchInput.length">
          <td></td>
        </ng-container>
        <ng-container *ngIf="(!selected || selected === 'default') && searchInput.length">
          <td></td>
        </ng-container>
        <ng-container *ngIf="!searchInput">
          <td>
            <label [for]="i">
              {{ member.legacyStoredMoney? '-' : ((p-1) * items  + (i-ghost+1)) }}
            </label>
          </td>
        </ng-container>
        <td>
          <label [for]="i">
            {{ member.dNickname }}
          </label>
        </td>
        <td>
          <label [for]="i">
            {{ member.joined | convertISO }}
          </label>
        </td>
        <td>
          <label [for]="i">
            {{ member.joined ? (member.sentTrialDCoin | number) : '-' }}
          </label>
        </td>
        <td>
          <label [for]="i">
            {{ member.isGhost? '-' : (member.currentDCoin | number) }}
          </label>
        </td>
        <td>
          <label [for]="i">
            {{ member.joined ? (member.storedMoney | currency: '':'symbol':'1.0-0') : '-' }}
          </label>
        </td>
        <td>
          <label [for]="i">
            {{ member.legacyStoredMoney | currency: '':'symbol':'1.0-0' }}
          </label>
        </td>
      </tr>
    </tbody>
  </table>
</main>

<!-- 手機板 table -->
<ng-template #RWD>

  <main *ngIf="!isLoading && !error && members.length" [@expandOnEnter]>

    <ng-container *ngIf="(selected === 'email' || selected === 'coin') && !searchInput.length">
      <button
        *ngIf="!none"
        (click)="checkAll()"
        class="btn btn--all">
        <i class="far fa-check-square"></i>
        全選
      </button>
      <button
        *ngIf="none"
        (click)="cancelAll()"
        class="btn btn--all">
      取消&nbsp;({{ memberId.length }})
      </button>
    </ng-container>

    <!-- 佔位的 -->
    <ng-container *ngIf="!selected || selected === 'default'">
      <div class="take--over"></div>
    </ng-container>

    <pagination-controls
      *ngIf="members.length > items"
      (pageChange)="p = $event"
      previousLabel="上一頁"
      nextLabel="下一頁">
    </pagination-controls>

    <table
      class="table table-vertical table-striped"
      *ngFor="let member of members  | filter:searchInput:'dNickname' |
      paginate: {
        itemsPerPage: items,
        currentPage: p
      };
        let i = index">
      <tbody>
        <tr>
          <!-- 非搜尋模式：正常顯示編號 -->
          <ng-container *ngIf="!searchInput">
            <td>
              編號（{{ member.legacyStoredMoney? '-' : ((p-1) * items  + (i-ghost+1)) }}）
            </td>
          </ng-container>
          <!-- 搜尋模式：!selected? 整列隱藏 : 空格佔位 -->
          <ng-container *ngIf="searchInput && (selected === 'email' || selected === 'coin')">
            <td>&nbsp;</td>
          </ng-container>
          <!-- 發信 -->
          <ng-container *ngIf="selected === 'email'">
            <td>
              <input
              *ngIf="!member.joined && !member.isGhost"
              [id]="i"
              type="checkbox"
              [value]="member.dUid"
              [checked]="memberId.includes(member.dUid)? true : false"
              (change)="toggleMemberId($event)">
            </td>
          </ng-container>
          <!-- 發錢 -->
          <ng-container *ngIf="selected === 'coin'">
            <td>
              <input
                *ngIf="member.joined && !member.isGhost"
                [id]="i"
                type="checkbox"
                [value]="member.dUid"
                [checked]="memberId.includes(member.dUid)? true : false"
                (change)="toggleMemberId($event)">
            </td>
          </ng-container>
          <!-- nothing / default -->
          <ng-container *ngIf="(!selected || selected === 'default') && !searchInput">
            <td></td>
          </ng-container>
        </tr>
        <tr>
          <td>暱稱</td>
          <td>{{ member.dNickname }}</td>
        </tr>
        <tr>
          <td>加入日期</td>
          <td>{{ member.joined | convertISO }}</td>
        </tr>
        <tr>
          <td>已派發體驗金</td>
          <td>
            {{ member.joined ? (member.sentTrialDCoin | number) : '-' }}
          </td>
        </tr>
        <tr>
          <td>持有鑽幣</td>
          <td>{{ member.isGhost? '-' : (member.currentDCoin | number) }}</td>
        </tr>
        <tr>
          <td>儲值金額</td>
          <td>
            {{ member.joined ? (member.storedMoney | currency: '':'symbol':'1.0-0') : '-' }}
          </td>
        </tr>
        <tr>
          <td>退出前之儲值金額</td>
          <td>
            {{ member.legacyStoredMoney | currency: '':'symbol':'1.0-0' }}
          </td>
        </tr>
      </tbody>
    </table>
  </main>
</ng-template>

<!-- 無資料狀態 -->
<app-no-info *ngIf="!members.length && !isLoading && !error"></app-no-info>
