<app-error-alert *ngIf="error"></app-error-alert>

<app-loading-spinner *ngIf="isLoading && !error"></app-loading-spinner>

<div *ngIf="!isLoading && !error" class="account col-md-8 col-lg-6 col-xl-3" [@expandOnEnter]>

  <div *ngIf="curWidth < 768" class="user">
    <a>
      <img src="assets/image/Diamondonline_personalbusiness_fudanlogo_200x200.png" alt="user__picture"
        class="user__picture">
    </a>
    <!-- <a>
        <img src="assets/image/Diamondonline_personalbusiness_file_80x80.png" alt="user__file" class="user__file">
      </a> -->
  </div>

  <table class="table table-vertical table-striped">
    <tbody>
      <tr>
        <th>遊戲暱稱</th>
        <td>{{ user.dNickname ? user.dNickname : '無' }}</td>
      </tr>
      <tr>
        <th>會員 ID</th>
        <td>{{ user.dAccount ? user.dAccount : '無' }}</td>
      </tr>
      <tr>
        <th>權限身份</th>
        <td>{{ user.role | identity }}</td>
      </tr>
      <tr>
        <th>介紹人（上級）</th>
        <td>{{ user.parent ? user.parent : '無' }}</td>
      </tr>
      <tr>
        <th>帳號創建日期</th>
        <td>{{ user.created | convertISO }}</td>
      </tr>
      <tr>
        <th>加入後台日期</th>
        <td>{{ user.joined | convertISO }}</td>
      </tr>
      <tr>
        <th>鑽幣</th>
        <td>{{ user.dCoin | number }}</td>
      </tr>
    </tbody>
  </table>

  <button *ngIf="user.role === 5 || user.role === 6" class="btn btn--request" (click)="openModal()">
    更改密碼
  </button>

  <button *ngIf="user.role === 3 || user.role === 4" class="btn btn--request" (click)="openSalaryModal()">
    薪資試算
    <i class="fas fa-hand-holding-usd"></i>
  </button>

</div>