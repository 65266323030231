import { Component, HostListener, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AuthenticationService } from '../shared/services/authentication.service';
import { SocialAuthService } from 'angularx-social-login';

// Model
import { User } from '../models/user';

// Service
import { AccountService } from '../main/account/account.service';
import { ReloadService } from '../shared/services/reload.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  providers: [AccountService]
})

export class NavComponent implements OnInit {
  @Input() permissionData?: any;
  @Output() drawerControl = new EventEmitter<boolean>();

  public curWidth: number = 0; // 視窗寬度
  drawerToggle: boolean = false; // RWD 控制 drawer
  url: string = '';

  userData?: User;
  constructor(
    private authenticationService: AuthenticationService,
    private socialAuthService: SocialAuthService,
    private accountService: AccountService,
    private router: Router,
    private reloadService: ReloadService) {

    router.events.subscribe((event) => {
      if(event instanceof NavigationEnd) {
        this.url = (this.router.url).split('/dashboard/').splice(1)[0];
        // console.log(this.url);
      }
    })
  }

  ngOnInit(): void {
    this.curWidth = window.innerWidth;
    this.getAccountData();
  }

  // 監聽 window 寬度
  @HostListener('window:resize', ['$event']) onResize() {
    this.curWidth = window.innerWidth;
  }

  /**
   * 點擊後登出
   */
  onLogout() {
    this.authenticationService.logout();
    this.FBsignOut();
  }

  /**
   * FB 登出
   */
  FBsignOut(): void {
    this.socialAuthService.signOut().then().catch(() => {
      console.log('FB根本沒登入過喔！');
    }) ;
  }

  /**
   * 切換日夜間模式
   */
  toggleDarkTheme(): void {

    // 切換日夜Icon
    let imgSrc =
      ($('.userRWD__switch--mode').attr('src') ===
        'assets/image/Diamondonline_personalbusiness_moon_80x80.png') ?
        'assets/image/Diamondonline_personalbusiness_sun_80x80.png' :
        'assets/image/Diamondonline_personalbusiness_moon_80x80.png';

    $('.userRWD__switch--mode').attr("src", imgSrc);


    // 切換日夜提示文字
    let modeText =
      $('.userRWD__switch--text').text() ===
        '點擊切換為夜間模式' ? '點擊切換為日間模式' : '點擊切換為夜間模式';

    $('.userRWD__switch--text').text(modeText);


    // $('app-main').toggleClass('dark-theme');
    $('app-root').toggleClass('dark-theme');
    this.closeBack();
  }

  /**
   * 關閉 drawer
   */
  closeBack() {
    if (this.curWidth < 768) {
      this.drawerToggle = false;
      this.drawerControl.emit(this.drawerToggle);
    }
  }

  // 取得帳號資訊
  getAccountData(): Promise<any> {
    return new Promise(() => {
      this.accountService.onGetAccount().subscribe({
        next: (response: any) => {
          this.userData = response;
          // console.log("ID給我！：" + this.userData?.dAccount);
          // resolve(response);
        },
        error: (error: any) => {
          console.log(error);
          // reject(error);
        }
      })
    })
  }

  getRouter() {
    this.reloadService.redirectTo(this.url);
  }
}
