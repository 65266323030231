import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'identity'
})

export class IdentityPipe implements PipeTransform {
  transform(value: number) {
    switch (value)
    {
      case 1:
        return 'None'

      case 2:
        return '會員'

      case 3:
        return '代理'

      case 4:
        return '總代'

      case 5:
        return '營運'

      case 6:
        return '管理者'

      default:
        return '待領養的孤兒'
    }
  }
}
