import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { CurrentAuth } from "src/app/models/currentAuth.model";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: 'root' })

export class CurAuthService {
  curAuth: number = 31;
  canDo: string[] = [];
  canCurAuth: CurrentAuth = {
    canLogin: true,
    canManipulate: true,
    canSetChannel: true,
    canSendEmail: true,
    canSendCoin: true
  }


  constructor(private http: HttpClient) {}

  /**
   * 取得目前的使用者權限
   */
  onGetCurAuth() {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.SellsServer}/api/Operations/GetCurreuntUserAuth`)
      .subscribe({
        next: (response: any) => {
          this.transAuth(response);
          resolve(this.canCurAuth)
        },
        error: (err: any) => {
          console.log(err);
          reject(err);
        }
      })
    })
  }

  /**
   * 將 登入後 取得的 CurAuth 存至 Service 以共享
   * 不管用！我的天
   * @param auth
   */
  saveCurAuth(auth: number) {
    this.curAuth = auth;
  }

  /**
   * 轉換 auth
   */
  transAuth(curAuth: number) {
    this.canDo = curAuth.toString(2).padStart(5, '0').split('');

    this.canCurAuth.canLogin = Boolean(Number(this.canDo[4]));
    this.canCurAuth.canManipulate = Boolean(Number(this.canDo[3]));
    this.canCurAuth.canSetChannel = Boolean(Number(this.canDo[2]));
    this.canCurAuth.canSendEmail = Boolean(Number(this.canDo[1]));
    this.canCurAuth.canSendCoin = Boolean(Number(this.canDo[0]));
  }
}
