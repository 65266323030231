// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

    // 環境名稱
    Name: 'Dev',

    // 各 API 接口
    LoginServer: 'https://dev-apigateway-official.diamondonline.com.tw/identity-player-api',

    // BQ 營運數據
    OperationDetail: 'https://lookerstudio.google.com/embed/u/0/reporting/b743240a-6ad4-4ec2-82a6-f6d813acf780/page/p_y38u638s0c',

    // BQ 帳務資訊
    FinanceDetail: 'https://lookerstudio.google.com/embed/u/0/reporting/4db0da49-fe5b-47a7-9fe4-e83da2729197/page/p_8vfy3qp80c',

    //後端URL
    SellsServer: 'https://dev-sells-sv.diamondonline.com.tw',

    //第三方登入返回位址
    SocialLoginURL: 'https://dev-sells-cl.diamondonline.com.tw/login',
    // SocialLoginURL: 'https://f20b-60-248-128-174.jp.ngrok.io/login',

    //第三方Channel ID
    LineChannelID: '1657106077',

    //第三方Google ID
    GoogleChannelID: '318443332245-natgiq35ca5sd9ok1oceah0c2n6nb6s0.apps.googleusercontent.com&',

    //第三方FB ID
    FBChannelID: '394392682702056',

    //第三方Apple ID
    AppleChannelID: 'com.fudanesports.diamondonline.dev.signin',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
