<app-search-date (searchTime)="getChannel($event)" [tip]="tipContent"></app-search-date>

<!-- 載入中 -->
<app-loading-spinner *ngIf="isLoading && !error"></app-loading-spinner>

<!-- 錯誤 -->
<app-error-alert *ngIf="error && !isLoading"></app-error-alert>

<!-- 有資料、無錯誤、非載入中 Desktop -->
<main *ngIf="!error && !isLoading && channels.length" [@expandOnEnter]>
  <table *ngIf="curWidth > 576; else RWD" class="table table-hover">
    <thead>
      <tr>
        <th rowspan="2">序號</th>
        <th rowspan="2">管道名稱</th>
        <th rowspan="2">建立日期</th>
        <th rowspan="2">修改日期</th>
        <th rowspan="2">瀏覽次數</th>
        <th colspan="3">來源</th>
        <th rowspan="2">連結</th>
        <th rowspan="2">QR Code</th>
      </tr>
      <tr>
        <th>&nbsp;&nbsp; PC &nbsp;&nbsp;</th>
        <th>&nbsp;&nbsp; iOS &nbsp;&nbsp;</th>
        <th>Android</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let c of channels">
        <td>{{ c.seq }}</td>
        <td>{{ c.name }}</td>
        <td>{{ c.created | convertISO }}</td>
        <td>{{ c.lastModify | convertISO }}</td>
        <td>
          {{
            (c.browsedTimes.PC
            + c.browsedTimes.iOS
            + c.browsedTimes.Android)
            | number
          }}
        </td>
        <td>{{ c.browsedTimes.PC }}</td>
        <td>{{ c.browsedTimes.iOS }}</td>
        <td>{{ c.browsedTimes.Android }}</td>
        <td>
          <a [href]="c.url" target="_new" title="點擊開啟新分頁" class="underline">
            {{ c.url | url:30 }}
          </a>
        </td>
        <td class="pointer" (click)="openModal(c.url)"><i class="fas fa-qrcode"></i>&nbsp;查看</td>
      </tr>
    </tbody>
  </table>

  <ng-template #RWD>
      <table class="table table-vertical table-striped" *ngFor="let c of channels">
        <tbody>
          <tr>
            <th colspan="2">序號</th>
            <td>{{ c.seq }}</td>
          </tr>
          <tr>
            <th colspan="2">管道名稱</th>
            <td>{{ c.name }}</td>
          </tr>
          <tr>
            <th colspan="2">建立日期</th>
            <td>{{ c.created | convertISO }}</td>
          </tr>
          <tr>
            <th colspan="2">修改日期</th>
            <td>{{ c.lastModify | convertISO }}</td>
          </tr>
          <tr>
            <th colspan="2">瀏覽次數</th>
            <td>
              {{
                (c.browsedTimes.PC
                + c.browsedTimes.iOS
                + c.browsedTimes.Android)
                | number
              }}
            </td>
          </tr>
          <tr>
            <th rowspan="4" class="p-0 source">來源</th>
          </tr>
          <tr>
            <th>PC</th>
            <td>{{ c.browsedTimes.PC }}</td>
          </tr>
          <tr>
            <th>iOS</th>
            <td>{{ c.browsedTimes.iOS }}</td>
          </tr>
          <tr>
            <th>Android</th>
            <td>{{ c.browsedTimes.Android }}</td>
          </tr>
          <tr>
            <th colspan="2">連結</th>
            <td>
              <a
              [href]="c.url"
              target="_new"
              class="underline p-0">
              點此前往
              </a>
          </td>
          </tr>
          <tr>
            <th colspan="2">QR Code</th>
            <td (click)="openModal(c.url)" class="underline">查看</td>
          </tr>
        </tbody>

      </table>
  </ng-template>

</main>


<!-- 無資料狀態 -->
<app-no-info *ngIf="!channels.length && !isLoading && !error"></app-no-info>
