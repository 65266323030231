<div class="wrap">

  <div class="modal-header">

    <h2 id="exampleModalLabel" class="modal-title">
      操作管理編輯</h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
  </div>

  <div class="modal-body">

    <form
    (ngSubmit)="onSubmit()"
    #f="ngForm"
    class="form">

      <div class="form__input--group">
        <label class="form__label">帳號資訊</label>
        <div>
          <input
          [(ngModel)]="body.accountInfo"

          type="radio"
          value="1"
          name="accountInfo"
          class="form__input"
          id="trueAccountInfo">
          <label for="trueAccountInfo">允許</label>
        </div>
        <div>
          <input
          [(ngModel)]="body.accountInfo"

          type="radio"
          value="0"
          name="accountInfo"
          class="form__input"
          id="falseAccountInfo">
          <label for="falseAccountInfo">禁止</label>
        </div>
      </div>

      <div class="form__input--group">
        <label class="form__label">營運操作</label>
        <div>
          <input
          [(ngModel)]="body.businessManage"

          type="radio"
          value="1"
          name="businessManage"
          class="form__input"
          id="trueBusinessManage">
          <label for="trueBusinessManage">允許</label>
        </div>
        <div>
          <input
          [(ngModel)]="body.businessManage"

          type="radio"
          value="0"
          name="businessManage"
          class="form__input"
          id="falseBusinessManage">
          <label for="falseBusinessManage">禁止</label>
        </div>
      </div>

      <div class="form__input--group">
        <label class="form__label">推廣控管</label>
        <div>
          <input
          [(ngModel)]="body.sellManage"

          type="radio"
          value="1"
          name="sellManage"
          class="form__input"
          id="trueSellManage">
          <label for="trueSellManage">允許</label>
        </div>
        <div>
          <input
          [(ngModel)]="body.sellManage"

          type="radio"
          value="0"
          name="sellManage"
          class="form__input"
          id="falseSellManage">
          <label for="falseSellManage">禁止</label>
        </div>
      </div>

      <!-- <div class="form__input--group">
        <label class="form__label">帳務報表</label>
        <div>
          <input
          [(ngModel)]="body.financeManage"

          type="radio"
          value="1"
          name="financeManage"
          class="form__input"
          id="trueFinanceManage">
          <label for="trueFinanceManage">允許</label>
        </div>
        <div>
          <input
          [(ngModel)]="body.financeManage"

          type="radio"
          value="0"
          name="financeManage"
          class="form__input"
          id="falseFinanceManage">
          <label for="falseFinanceManage">禁止</label>
        </div>
      </div> -->

      <div class="form__input--group">
        <label class="form__label">代理控管</label>
        <div>
          <input
          [(ngModel)]="body.agentManage"

          type="radio"
          value="1"
          name="agentManage"
          class="form__input"
          id="trueAgentManage">
          <label for="trueAgentManage">允許</label>
        </div>
        <div>
          <input
          [(ngModel)]="body.agentManage"

          type="radio"
          value="0"
          name="agentManage"
          class="form__input"
          id="falseAgentManage">
          <label for="falseAgentManage">禁止</label>
        </div>
      </div>

      <div class="form__input--group">
        <label class="form__label">會員控管</label>
        <div>
          <input
          [(ngModel)]="body.memberManage"

          type="radio"
          value="1"
          name="memberManage"
          class="form__input"
          id="trueMemberManage">
          <label for="trueMemberManage">允許</label>
        </div>
        <div>
          <input
          [(ngModel)]="body.memberManage"

          type="radio"
          value="0"
          name="memberManage"
          class="form__input"
          id="falseMemberManage">
          <label for="falseMemberManage">禁止</label>
        </div>
      </div>

      <div class="form__input--group">
        <label class="form__label">系統管理</label>
        <div>
          <input
          [(ngModel)]="body.systemManage"

          type="radio"
          value="1"
          name="systemManage"
          class="form__input"
          id="trueSystemManage">
          <label for="trueSystemManage">允許</label>
        </div>
        <div>
          <input
          [(ngModel)]="body.systemManage"

          type="radio"
          value="0"
          name="systemManage"
          class="form__input"
          id="falseSystemManage">
          <label for="falseSystemManage">禁止</label>
        </div>
      </div>

      <button
      class="btn btn--request"
      type="submit"
      [disabled]="state !== '儲存'"
      (click)="onSubmit()">
      <span
      *ngIf="isStoring"
      class="spinner-border spinner-border-sm">
      </span>
        {{ state }}
      </button>

    </form>

  </div>

</div>
