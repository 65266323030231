import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

// Environment

import { environment } from 'src/environments/environment';

// Service
import { CheckboxService } from 'src/app/shared/services/checkbox.service';
import { ReloadService } from 'src/app/shared/services/reload.service';

@Component({
  selector: 'app-role-change-modal',
  templateUrl: './role-change-modal.component.html',
  styleUrls: ['./role-change-modal.component.scss']
})
export class RoleChangeModalComponent implements OnInit {
  title: string | null = null;
  @ViewChild('f', { static: false })
  roleChangeForm!: NgForm;
  // button 狀態管理
  state: string = '儲存';
  isStoring: boolean = false;

  role: number = 0;

  constructor(public modalRef: MdbModalRef<RoleChangeModalComponent>,
    private http: HttpClient,
    private checkboxService: CheckboxService,
    private reloadService: ReloadService) { }

  ngOnInit(): void {
  }

  onSubmit() {

    // Showing spinner & State change
    this.isStoring = true;
    this.state = '儲存中';

    const body = {
      userIdList: this.checkboxService.getGaIdList(),
      role: parseInt(this.roleChangeForm.value.role, 10)
    }

    // console.log(body);

    this.http
    .put(`${environment.SellsServer}/api/Operations/edit/user/role`, body)
    .subscribe({
      next: (response: any) => {
        setTimeout(() => {
          this.isStoring = false;
          this.state = '儲存成功';

          setTimeout(() => {
            this.modalRef.close();
            this.reloadService.redirectTo('business-manage/permission-setting')
          }, 1500)

        }, 1500)
      },
      error: (err: any) => {
        setTimeout(() => {
          this.isStoring = false;
          this.state = '儲存失敗';
          console.log(err);

          setTimeout(() => {
            this.state = '儲存';
          }, 2000)

        }, 1500)
      }
    })
  }

}
