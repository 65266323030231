import { Component, HostListener, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { EditPermissionModalComponent } from './edit-permission-modal/edit-permission-modal.component';

// Environment
import { environment } from 'src/environments/environment';

// Service
import { RoleService } from 'src/app/shared/services/role.service';

// Module
import { Role } from 'src/app/models/role.module';

@Component({
  selector: 'app-backstage-manage',
  templateUrl: './backstage-manage.component.html',
  styleUrls: ['./backstage-manage.component.scss']
})
export class BackstageManageComponent implements OnInit {
  modalRef: MdbModalRef<EditPermissionModalComponent> | null = null;
  isLoading: boolean = false; // 載入狀態
  error: boolean = false; // 錯誤
  curWidth: number = 0; // 視窗寬度

  // Default Data

  permission: Role[] = [];

  constructor(
    private modalService: MdbModalService,
    private http: HttpClient,
    private roleService: RoleService) { }

  ngOnInit(): void {
    // 依據 width 決定渲染電腦版 or 手機板
    this.curWidth = window.innerWidth;

    this.getRoleAuthorizations();
  }

  // 監聽 window 寬度
  @HostListener('window:resize', ['$event']) onResize() {
    this.curWidth = window.innerWidth;
  }

  openModal(role: number, roleAuth: number) {
    this.modalRef = this.modalService.open(EditPermissionModalComponent, {
      modalClass: 'modal-dialog-centered'
    });
    this.roleService.setRole(role, roleAuth);
  }

  getRoleAuthorizations() {
    this.isLoading = true;

    this.http.get(`${environment.SellsServer}/api/System/Role/Authorizations`).subscribe({
      next: (response: any) => {
        setTimeout(() => {
          // 成功，停止載入並刷新資料
          // console.log(response);
          this.isLoading = false;
          this.fetchData(response as Role[]);
        }, 300)
      },
      error: (err: any) => {
        setTimeout(() => {
          this.isLoading = false;
          this.error = true;
          console.log(err);
        }, 1000)
      }
    })
  }

  fetchData(data: Role[]) {
    this.permission = data;
  }
}
