import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "src/environments/environment";
import { MyAgentInfo } from "src/app/models/myAgentInfo";

@Injectable({ providedIn: 'root' })

export class MyAgentService {
  myAgent: MyAgentInfo = {
    dNickname: "",
    dAccount: "",
    dCoin: 0,
    trialDCoin: 0,
    storedMoney: 0
  };

  constructor(private http: HttpClient) { }

  /**
   * Call API
   * @returns
   */
  getMyAgent() {
    return this.http.get(`${environment.SellsServer}/api/Account/MyAgentInfo`)
  }

  /**
   * Pass current dCoin
   * @param coin
   */
  setdCoin(coin: number) {
    this.myAgent.dCoin = coin;
  }

  /**
   * Pass current trialDCoin
   * @param coin
   */
  setTrialCoin(coin: number) {
    this.myAgent.trialDCoin = coin;
  }

  /**
   *
   * @returns Get current dCoin
   */
  getCurdCoin() {
    return this.myAgent.dCoin
  }

  /**
   *
   * @returns Get current trialDCoin
   */
  getCurTrialDCoin() {
    return this.myAgent.trialDCoin
  }
}

