import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from "@angular/common/http";
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

// Environment
import { environment } from 'src/environments/environment';

// Service
import { CheckboxService } from 'src/app/shared/services/checkbox.service';
import { ReloadService } from 'src/app/shared/services/reload.service';
import { MyAgentService } from 'src/app/shared/component/my-agent/myAgent.service';

@Component({
  selector: 'app-send-dcoin-modal',
  templateUrl: './send-mdcoin-modal.component.html',
  styleUrls: ['./send-mdcoin-modal.component.scss']
})
export class SendMDcoinModalComponent implements OnInit {
  mdcoinForm!: FormGroup;
  // Button 狀態
  state: string = '確認';
  isStoring: boolean = false;

  // 超過持有數量
  error: boolean = false;
  errorMessage: string = '　';
  regCheck = new RegExp(/^[0-9]*$/);

  constructor(
    public modalRef: MdbModalRef<SendMDcoinModalComponent>,
    private http: HttpClient,
    private checkboxService: CheckboxService,
    private reloadService: ReloadService,
    private myAgentService: MyAgentService
  ) { }

  ngOnInit(): void {
    this.mdcoinForm = new FormGroup({
      'kind': new FormControl('1'),
      'amount': new FormControl(null, [
        Validators.required,
        Validators.min(1),
        Validators.pattern(/^[0-9]*$/)
      ]),
      'check': new FormControl(null, [
        Validators.requiredTrue
      ])
    })

    // 取得被選定之 ID 數量
    const idLength = this.checkboxService.getMemberIdList().length;

    // 即時偵測表單值，顯示相應的錯誤訊息 & disable button
    this.mdcoinForm.valueChanges.subscribe(
      (value => {
        const number = value.amount;
        const kind = value.kind;
        const curdCoin = this.myAgentService.getCurdCoin();
        const curdTrialDCoin = this.myAgentService.getCurTrialDCoin();
        if (number < 1) {
          // Error: 未達1元
          this.error = true;
          this.errorMessage = '金額需大於等於 1';
        } else if ( number > (curdTrialDCoin / idLength) && kind === '1') {
          // Error: 超過持有體驗金數量
          this.error = true;
          this.errorMessage = '已超過持有體驗金數量';
        } else if (number > (curdCoin / idLength) && kind === '2') {
          // Error: 超過持有鑽幣數量
          this.error = true;
          this.errorMessage = '已超過持有鑽幣數量';
        } else if (!this.regCheck.test(number)) {
          // Error: 金額非正整數
          this.error = true;
          this.errorMessage = '請輸入整數';
        } else {
          // Typing 不顯示錯誤訊息
          this.error = false;
        }
      })
    )

    this.mdcoinForm.controls['amount'].valueChanges.subscribe(() => {
      this.mdcoinForm.controls['check'].setValue(null)
    })
  }

  onSubmit() {
    const value = this.mdcoinForm.value;
    const body = {
      toUidList: this.checkboxService.getMemberIdList(),
      kind: parseInt(value.kind, 10),
      amount: value.amount
    }

    // console.log(body);

    // Showing spinner & State change
    this.isStoring = true;
    this.state = '發送中';

    // Make sure the form is VALID. In case there is situation caused INVALID but missing catch
    // Because it won't be block though the form is INVALID...
    if (this.mdcoinForm.status === 'VALID') {
      this.http
        .post(`${environment.SellsServer}/api/Members/SendDCoin`, body)
        .subscribe({
          // 成功
          next: (response: any) => {
            setTimeout(() => {
              this.isStoring = false;
              this.state = '發送成功';

              setTimeout(() => {
                // 1.5 秒後關閉 modal 並重導向
                this.modalRef.close();
                this.reloadService.redirectTo('member-manage');
              }, 1500)

            }, 1000)
          },
          error: (err: any) => {
            setTimeout(() => {
              this.isStoring = false;
              this.state = '發送失敗';
              console.log(err);

              setTimeout(() => {
                this.mdcoinForm.controls['amount'].reset();
                this.state = '確認';
              }, 2000)

            }, 1500)
          }
        })
    } else {
      this.error = true;
      this.errorMessage = '無效表單';
    }
  }

}
