import { Component, HostListener, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';

// Modal
import { AccountModalComponent } from './account-modal/account-modal.component';
import { SalaryModalComponent } from './salary-modal/salary-modal.component'

// Service
import { AccountService } from './account.service';

// Model
import { User } from 'src/app/models/user';

// Animation
import { expandOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  animations: [
    expandOnEnterAnimation()
  ]
})

export class AccountComponent implements OnInit {

  public curWidth: number = 0; // 視窗寬度
  modalRef: MdbModalRef<AccountModalComponent> | null = null;
  isLoading: boolean = false;
  error: boolean = false;

  // User Info
  user: User = {
    dNickname: '',
    dAccount: '',
    role: 0,
    parent: '',
    created: '',
    joined: '',
    dCoin: 0,
  };

  constructor(
    private modalService: MdbModalService,
    private accountService: AccountService
  ) { }

  ngOnInit(): void {
    // 依據 width 決定渲染電腦版 or 手機板
    this.curWidth = window.innerWidth;

    this.getAccount();
  }

  // 監聽 window 寬度
  @HostListener('window:resize', ['$event']) onResize() {
    this.curWidth = window.innerWidth;
  }

  openModal() {
    this.modalRef = this.modalService.open(AccountModalComponent, {
      modalClass: "modal-dialog-centered"
    });
  }

  openSalaryModal() {
    this.modalRef = this.modalService.open(SalaryModalComponent, {
      modalClass: "modal-dialog-centered"
    });
  }

  /**
   * 取得帳號資訊
   */
  getAccount() {
    // 載入中
    this.isLoading = true;

    this.accountService
      .onGetAccount()
      .subscribe({
        next: (response: any) => {
          // 回傳成功，停止載入、刷新資料
          setTimeout(() => {
            this.isLoading = false;
            this.fetchData(response as User);
            this.accountService.setRole(this.user.role);
          }, 500)
        },
        error: (err: any) => {
          // 回傳失敗，停止載入、顯示 fail
          setTimeout(() => {
            this.isLoading = false;
            this.error = true;
            console.log(err);
          }, 1000)
        }
      });
  }

  fetchData(data: User) {
    this.user = data;
  }
}
