import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

// Environment
import { environment } from 'src/environments/environment';

// Service
import { GetTimeService } from 'src/app/shared/services/getTime.service';



@Injectable({providedIn: 'root'})

export class AccountService {
    role: number = 0;

    constructor(
      private http: HttpClient,
      private getTimeService: GetTimeService) { }

    /**
     *
     * @returns 取得帳號資訊
     */
    onGetAccount() {
      return this.http.get(`${environment.SellsServer}/api/Account/MyInfo`);
    }

    /**
     *
     * @returns 薪水試算
     */
    onGetSalary() {
      const start = this.getTimeService.transferStart();
      const end
       = this.getTimeService.transferEnd();

      return this.http.get(`${environment.SellsServer}/api/Salary/from/${start}/to/${end}`)
    }

    /**
     * 存入角色身分
     * @param role
     */
    setRole(role: number) {
      this.role = role;
    }

    /**
     * 取得角色身分
     * @returns
     */
    getRole() {
      return this.role
    }
}
