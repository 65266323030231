import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { HttpClient } from "@angular/common/http";

// Environment
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';


@Component({
  selector: 'app-account-modal',
  templateUrl: './account-modal.component.html',
  styleUrls: ['./account-modal.component.scss'],
})

export class AccountModalComponent implements OnInit {
  // Form
  passwordForm!: FormGroup;

  // Regular Expression
  regCheck = new RegExp(/^[A-Za-z0-9]*$/);

  // 密碼錯誤訊息管理
  errorMessage: string = '';

  // button 狀態管理
  isStoring: boolean = false;
  state: string = '儲存';

  constructor(
    public modalRef: MdbModalRef<AccountModalComponent>,
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.passwordForm = new FormGroup({
      'oldPassword': new FormControl(null, [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(16)
      ]),
      'newPassword': new FormControl(null, [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(16)
      ]),
      'newPasswordDoubleCheck': new FormControl(null, [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(16)
      ])
    })
  }

  // typing 時不顯示 errorMessage
  onFocus() {
    this.errorMessage = '';
  }

  // 清空 new 欄位要求重填
  resetNew() {
    this.passwordForm.controls['newPassword'].reset();
    this.passwordForm.controls['newPasswordDoubleCheck'].reset();
  }

  onSubmit() {
    const value = this.passwordForm.value;

    if (value.newPassword !== value.newPasswordDoubleCheck) {
      // Error: 新密碼輸入不一致
      this.errorMessage = '新密碼輸入不一致，請重新輸入';
      this.resetNew();
    } else if (value.oldPassword === value.newPassword) {
      // Error: 新舊密碼不可相同
      this.errorMessage = '新密碼不可與舊密碼相同';
      this.resetNew();
    } else if (!this.regCheck.test(value.newPassword)) {
      // Error: 密碼含有特殊符號／不符合規則
      this.errorMessage = '密碼不可包含特殊符號';
      this.resetNew();
    } else if (this.passwordForm.status === 'VALID') {
      // Success: 開始 Call API

      // Showing spinner & State change
      this.isStoring = true;
      this.state = '儲存中';

      const body = value;
      this.http
        .put(`${environment.SellsServer}/api/Account/ResetPassword`, body)
        .subscribe(
          // 成功
          () => {
            setTimeout(() => {
              this.isStoring = false;
              this.state = '更改成功 Ｏ';

              setTimeout(() => {
                // 1.5 秒後關閉 modal
                this.modalRef.close();
                this.authenticationService.logout();
              }, 1500)

            }, 1000)

            // this.timeOutService.success(
            //   1000,
            //   this.state,
            //   '更改成功 Ｏ',
            //   this.isStoring)
          },
          // 失敗
          err => {
            setTimeout(() => {
              this.isStoring = false;
              if (err.error[0].code === 'PasswordMismatch') {
                this.state = '更改失敗，請檢查舊密碼是否正確'
              } else {
                this.state = '更改失敗'
              }

              this.passwordForm.reset();

              setTimeout(() => {
                this.state = '儲存';
              }, 3000)

            }, 1000)
          })
    }

  }
}
