import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

// Service
import { ReloadService } from 'src/app/shared/services/reload.service';

// Environment
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-boss-modal',
  templateUrl: './add-boss-modal.component.html',
  styleUrls: ['./add-boss-modal.component.scss']
})
export class AddBossModalComponent implements OnInit {
  addGaForm!: FormGroup;

  curWidth: number = 0; // 視窗寬度

  target = 0; // 業績目標，預設0
  isStoring: boolean = false;
  state: string = '儲存';

  regCheck = new RegExp(/^09\d{8}$/);
  isPhoneDisabled = false;
  result: string = '-----會員暱稱-----'; // 搜尋結果
  getNickName: boolean = false; // 是否取得暱稱

  constructor(
    public modalRef: MdbModalRef<AddBossModalComponent>,
    private http: HttpClient,
    private reloadService: ReloadService,
  ) { }

  ngOnInit(): void {
    this.curWidth = window.innerWidth;

    this.addGaForm = new FormGroup({
      'cellphone': new FormControl(null, [
        Validators.required,
        Validators.pattern(/^09\d{8}$/)
      ]),
      'check': new FormControl(null, Validators.requiredTrue),
    });

    this.addGaForm.controls['cellphone'].valueChanges.subscribe(() => {
      this.result = '-----會員暱稱-----';
      this.getNickName = false;
      this.addGaForm.controls['check'].setValue(null);
    })
  }


  // 搜尋會員
  onSearch() {
    const cellphone = this.addGaForm.value.cellphone;

    if (this.regCheck.test(cellphone)) {
      this.http
        .get(`${environment.SellsServer}/api/Operations/get/nickname?cellphone=${cellphone}`)
        .subscribe({
          next: (response: any) => {
            if (response === null) {
              this.result = '-----查無此人-----'
              this.getNickName = false;
            } else {
              this.result = response;
              this.getNickName = true;
            }
          },
          error: (err: any) => {
            console.log(err);
            this.result = '-----錯誤，系統異常-----';
            this.getNickName = false;
          }
        })
    } else {
      this.result = '請輸入正確的手機格式'
    }

  }

  // 新增總代
  onSubmit() {

    if (!this.addGaForm.invalid) {
      // Success: 開始 Call API

      // Showing spinner & State change
      this.isStoring = true;
      this.state = '儲存中';

      const body = {
        cellphone: this.addGaForm.value.cellphone
      }


      this.http
        .post(`${environment.SellsServer}/api/Operations/create/ga`, body)
        .subscribe({
          // 成功
          next: (response: any) => {
            setTimeout(() => {
              this.isStoring = false;
              this.state = '儲存成功 Ｏ';

              setTimeout(() => {
                // 1.5 秒後關閉 modal
                this.modalRef.close();
                // 重刷頁面
                this.reloadService.redirectTo('business-manage/ga-setting')
              }, 1500)

            }, 1500)
          },
          // 失敗
          error: (err: any) => {
            setTimeout(() => {
              this.isStoring = false;
              this.state = '儲存失敗 Ｘ';
              console.log(this.addGaForm.value.cellphone);
              console.log(err);

              setTimeout(() => {
                this.state = '儲存';
                this.addGaForm.reset();
                this.getNickName = false;
                this.result = '-----會員暱稱-----';
              }, 2000)

            }, 1500)
          }
        })
    }
  }
}
