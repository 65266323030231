<header *ngIf="curWidth >= 768; else RWDHeader" class="header">

  <div class="header__heading">

    <a title="點擊切換日夜間模式" (click)="toggleDarkTheme()">
      <img class="header__mode" src="assets/image/Diamondonline_personalbusiness_moon_80x80.png" alt="日夜間模式">
    </a>

    <a
    routerLink="/"
    (click)="onLogout()"
    class="header__logout"
    title="點此登出">
      <img class="header__arrow" src="assets/image/Diamondonline_personalbusiness_out_80x80.png" alt="logout">
      <span class="header__text">登出</span>
    </a>

  </div>

</header>

<ng-template #RWDHeader>
  <header class="RWDHeader">
    <span></span>
    <h2 class="RWDHeader__title">
      <a routerLink="account" (click)="closeBack()">
        Diamond online 推廣系統
      </a>
    </h2>
    <a>
      <img class="RWDHeader__hamburger" src="assets/image/Diamondonline_personalbusiness_list_80x80.png" alt="選單" (click)="onBack()">
    </a>
  </header>
</ng-template>
