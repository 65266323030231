<!-- <ng-container *ngIf="!ifLogin; else nav">
  <app-login></app-login>
</ng-container>

<ng-template class="container-fluid" #nav>
  <router-outlet></router-outlet>
</ng-template> -->

<div class="container-fluid">
  <router-outlet></router-outlet>
</div>
