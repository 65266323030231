<div class="wrap">

  <div class="modal-header">

    <h2 id="exampleModalLabel" class="modal-title">
      新增總代</h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
  </div>

  <div class="modal-body">

    <form
    [formGroup]="addGaForm"
    (ngSubmit)="onSubmit()"
    class="form"
    ngNativeValidate>

      <div class="form__input--group">
        <label class="form__label" for="cellphone">搜尋會員</label>
        <div>
          <input
          formControlName="cellphone"
          name="cellphone"
          maxlength="10"
          type="text"
          class="form__input form__placeholder"
          id="cellphone"
          placeholder="請輸入鑽很大會員之手機號碼">

          <span class="form__unit" (click)="onSearch()"><i class="fas fa-search pointer"></i></span>
        </div>
      </div>

      <div class="form__input--group">
        <label class="form__label">搜尋結果</label>
        <div>
          <input
          type="text"
          disabled
          class="form__input form__placeholder form__result"
          [placeholder]="result">

          <span><i class="fas fa-user"></i></span>
        </div>
      </div>

      <div class="d-flex justify-content-center align-items-center">
        <input
        formControlName="check"
        id="check"
        name="check"
        type="checkbox">
        <label for="check" class="ms-2 fs-4">確認</label>
      </div>

      <button
      type="submit"
      class="btn btn--request"
      [disabled]="state !== '儲存' || !addGaForm.valid || !getNickName">
        <span *ngIf="isStoring" class="spinner-border spinner-border-sm"></span>
        {{ state }}
      </button>

    </form>

  </div>

</div>
