import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'url'
})

export class UrlPipe implements PipeTransform {
  transform(value: any, long: number) {
    if (value.length >= long) {
      return value.slice(0, long) + '...'
    } else {
      return 'nonono'
    }
  }
}
