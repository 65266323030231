import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

// QR Code
import { QRCodeModule } from 'angularx-qrcode';

// 分頁
import { NgxPaginationModule } from 'ngx-pagination';

// 複製到剪貼版
import { ClipboardModule } from 'ngx-clipboard';

// 逾時登出
import { UserIdleModule } from 'angular-user-idle';

// Environment
import { environment } from 'src/environments/environment';

// UI library
import { MaterialExampleModule } from './material.module';
import { MdbModule } from './mdb.module';

// Shared
import { LoadingSpinnerComponent } from "./shared/component/loading-spinner/loading-spinner.component";
import { ErrorAlertComponent } from "./shared/component/error-alert/error-alert.component";
import { NoInfoComponent } from "./shared/component/no-info/no-info.component";
import { MyAgentComponent } from './shared/component/my-agent/my-agent.component';
import { SearchDateComponent } from './shared/component/search-date/search-date.component';

// FB
import { FacebookLoginProvider, SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';

// Component
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NavComponent } from './nav/nav.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MainComponent } from './main/main.component';
import { LoginComponent } from './login/login.component';
import { AccountComponent } from './main/account/account.component';
import { BusinessManageComponent } from './main/business-manage/business-manage.component';
import { SellManageComponent } from './main/sell-manage/sell-manage.component';
import { FinanceReportComponent } from './main/finance-report/finance-report.component';
import { AgentManageComponent } from './main/agent-manage/agent-manage.component';
import { MemberManageComponent } from './main/member-manage/member-manage.component';
import { SystemManageComponent } from './main/system-manage/system-manage.component';
import { OperationDetailComponent } from './main/business-manage/operation-detail/operation-detail.component';
import { FinanceDetailComponent } from './main/business-manage/finance-detail/finance-detail.component';
import { GaSettingComponent } from './main/business-manage/ga-setting/ga-setting.component';
import { PermissionSettingComponent } from './main/business-manage/permission-setting/permission-setting.component';
import { BackstageRecordComponent } from './main/system-manage/backstage-record/backstage-record.component';
import { BackstageManageComponent } from './main/system-manage/backstage-manage/backstage-manage.component';
import { SellResultComponent } from './main/sell-manage/sell-result/sell-result.component';
import { SellSettingComponent } from './main/sell-manage/sell-setting/sell-setting.component';

// Modal
import { AccountModalComponent } from './main/account/account-modal/account-modal.component';
import { SalaryModalComponent } from './main/account/salary-modal/salary-modal.component';
import { GoalSettingModalComponent } from './main/business-manage/ga-setting/goal-setting-modal/goal-setting-modal.component';
import { SendGaCoinModalComponent } from './main/business-manage/ga-setting/send-ga-coin-modal/send-ga-coin-modal.component'
import { AddBossModalComponent } from './main/business-manage/ga-setting/add-boss-modal/add-boss-modal.component';
import { PermissionChangeModalComponent } from './main/business-manage/permission-setting/permission-change-modal/permission-change-modal.component';
import { RoleChangeModalComponent } from './main/business-manage/permission-setting/role-change-modal/role-change-modal.component';
import { SellResultModalComponent } from './main/sell-manage/sell-result/sell-result-modal/sell-result-modal.component';
import { NewChannelModalComponent } from './main/sell-manage/sell-setting/new-channel-modal/new-channel-modal.component';
import { SellModifyModalComponent } from './main/sell-manage/sell-setting/sell-modify-modal/sell-modify-modal.component';
import { AuthInterceptorService } from './auth-interceptor.service';
import { MailModalComponent } from './main/agent-manage/mail-modal/mail-modal.component';
import { MailMModalComponent } from './main/member-manage/mail-modal/mail-modal.component';
import { AchievementModalComponent } from './main/agent-manage/achievement-modal/achievement-modal.component';
import { SendDcoinModalComponent } from './main/agent-manage/send-dcoin-modal/send-dcoin-modal.component';
import { SendMDcoinModalComponent } from './main/member-manage/send-mdcoin-modal/send-mdcoin-modal.component';
import { EditPermissionModalComponent } from './main/system-manage/backstage-manage/edit-permission-modal/edit-permission-modal.component';

// Pipe
import { SplitTitlePipe } from './pipes/split-title.pipe';
import { ConvertISOPipe } from './pipes/convertISO.pipe';
import { IdentityPipe } from './pipes/identity.pipe';
import { BitWisePipe } from './pipes/bitWise.pipe';
import { UrlPipe } from './pipes/url.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { ToFixedPipe } from './pipes/toFixed.pipe';

@NgModule({
    declarations: [
        AppComponent,
        LoadingSpinnerComponent,
        DashboardComponent,
        NavComponent,
        FooterComponent,
        HeaderComponent,
        LoginComponent,
        ErrorAlertComponent,
        NoInfoComponent,
        MainComponent,
        AccountComponent,
        SalaryModalComponent,
        BusinessManageComponent,
        SellManageComponent,
        FinanceReportComponent,
        AgentManageComponent,
        MemberManageComponent,
        SystemManageComponent,
        OperationDetailComponent,
        FinanceDetailComponent,
        GaSettingComponent,
        PermissionSettingComponent,
        BackstageRecordComponent,
        BackstageManageComponent,
        SellResultComponent,
        SellSettingComponent,
        MyAgentComponent,
        SearchDateComponent,
        AccountModalComponent,
        GoalSettingModalComponent,
        SendGaCoinModalComponent,
        AddBossModalComponent,
        PermissionChangeModalComponent,
        RoleChangeModalComponent,
        SellResultModalComponent,
        NewChannelModalComponent,
        SellModifyModalComponent,
        MailModalComponent,
        AchievementModalComponent,
        SendDcoinModalComponent,
        MailMModalComponent,
        SendMDcoinModalComponent,
        EditPermissionModalComponent,
        SplitTitlePipe,
        ConvertISOPipe,
        IdentityPipe,
        BitWisePipe,
        UrlPipe,
        FilterPipe,
        SafePipe,
        ToFixedPipe
    ],
    providers: [
      {
        provide: 'SocialAuthServiceConfig',
        useValue: {
          autoLogin: false,
          providers: [
            {
              id: FacebookLoginProvider.PROVIDER_ID,
              provider: new FacebookLoginProvider(`${environment.FBChannelID}`),
            },
          ],
        } as SocialAuthServiceConfig,
      },
      {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptorService,
          multi: true
      },
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        QRCodeModule,
        NgxPaginationModule,
        ClipboardModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialExampleModule,
        MdbModule,
        SocialLoginModule,
        // 1800s(30min) 後自動登出
        UserIdleModule.forRoot({idle: 1795, timeout: 5, ping: 0})
    ]
})
export class AppModule { }
