import { Component, HostListener, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Modal
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { SellResultModalComponent } from '../sell-result/sell-result-modal/sell-result-modal.component';
import { SellModifyModalComponent } from './sell-modify-modal/sell-modify-modal.component';
import { NewChannelModalComponent } from './new-channel-modal/new-channel-modal.component';

// Enviroment
import { environment } from 'src/environments/environment';

// Module
import { CurrentAuth } from 'src/app/models/currentAuth.model';
import { ChannelResult } from 'src/app/models/channel.module';

// Service
import { GetTimeService } from 'src/app/shared/services/getTime.service';
import { ParamsService } from '../params.service';
import { ClipboardService } from 'ngx-clipboard';
import { CurAuthService } from 'src/app/shared/services/curAuth.service';

// Animation
import { expandOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-sell-setting',
  templateUrl: './sell-setting.component.html',
  styleUrls: ['./sell-setting.component.scss'],
  animations: [
    expandOnEnterAnimation()
  ]
})
export class SellSettingComponent implements OnInit {
  // modal
  modalRef: MdbModalRef<SellResultModalComponent> | null = null;
  isLoading: boolean = false; // 載入狀態
  error: boolean = false; // 錯誤
  curWidth: number = 0; // 視窗寬度

  // 權限確認
  curAuth: CurrentAuth = {
    canLogin: true,
    canManipulate: true,
    canSetChannel: true,
    canSendEmail: true,
    canSendCoin: true
  };

  channels: ChannelResult[] = []; // 推廣成果

  constructor(
    private modalService: MdbModalService,
    private http: HttpClient,
    private paramsService: ParamsService,
    private getTimeService: GetTimeService,
    private clipboardApi: ClipboardService,
    private currentAuthService: CurAuthService) { }

  async ngOnInit(): Promise<void> {
    // 依據 width 決定渲染電腦版 or 手機板
    this.curWidth = window.innerWidth;

    await this.getCurAuth();
    this.getChannel();
  }

  // 監聽 window 寬度
  @HostListener('window:resize', ['$event']) onResize() {
    this.curWidth = window.innerWidth;
  }

  openModal(url: string) {
    this.modalRef = this.modalService.open(SellResultModalComponent, {
      modalClass: 'modal-dialog-centered'
    });

    this.paramsService.saveParams(url);
  }

  openEditModal(seq: number) {
    this.modalRef = this.modalService.open(SellModifyModalComponent, {
      modalClass: 'modal-dialog-centered'
    });

    this.paramsService.saveParams(seq);
  }

  openNewChannelModal() {
    this.modalRef = this.modalService.open(NewChannelModalComponent, {
      modalClass: 'modal-dialog-centered'
    });
  }

  /**
   * 取得目前角色權限
   */
  async getCurAuth() {
    this.isLoading = true;
    await this.currentAuthService.onGetCurAuth()
    .then((response: any) => {
      this.curAuth = response;
      this.isLoading = false;
      // console.log(this.curAuth);
    })
    .catch(err => {
      this.error = true;
      console.log(err);
    })
  }

  /**
   * 取得推廣管道列表
   */
  getChannel() {
    const today = new Date();
    const start = this.getTimeService.transferStart(today.toISOString());
    const end = this.getTimeService.transferEnd(today.toISOString());

    this.isLoading = true;

    this.http
      .get<ChannelResult>(`${environment.SellsServer}/api/Channels/from/${start}/to/${end}`)
      .subscribe({
        next: (response: any) => {
          setTimeout(() => {
            // 成功，停止載入並刷新資料
            this.isLoading = false;
            this.channels = response;
          }, 500)
        },
        error: (err: any) => {
          setTimeout(() => {
            // 失敗，停止載入並顯示錯誤訊息
            this.isLoading = false;
            this.error = true;
            console.log(err);
          }, 2000)
        }
      })
  }

  copyUrl(url: string) {
    this.clipboardApi.copyFromContent(url);
  }
}
