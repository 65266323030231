import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Enviroment
import { environment } from 'src/environments/environment';

// Module
import { ChannelResult } from 'src/app/models/channel.module';

// Service
import { GetTimeService } from 'src/app/shared/services/getTime.service';

@Injectable({
  providedIn: 'root'
})
export class SellResultService {

  constructor(
    private http: HttpClient,
    private getTimeService: GetTimeService
  ) { }

  /**
   *
   * @param start 取得推廣渠道列表
   * @param end
   * @returns
   */
  getChannel(start?: string, end?: string) {
    const startISO = this.getTimeService.transferStart(start);
    const endISO = this.getTimeService.transferEnd(end);

    // -7 天
    const d = new Date();
    d.setDate(d.getDate() - 7);
    const pastSeven = `${d.toISOString().slice(0, 10)}T00:00:00.000+08:00`;

    // 有參數，則搜索該指定區間
    // 無參數，預設 start 為過去七日
    return this.http
      .get<ChannelResult>(`${environment.SellsServer}/api/Channels/from/${start ? startISO : pastSeven}/to/${endISO}`)
      .pipe(
        catchError(this.handleError)
      )
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('An error from server.'));
  }
}
