import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

// Module
import { CurrentAuth } from 'src/app/models/currentAuth.model';

// Service
import { CurAuthService } from 'src/app/shared/services/curAuth.service';

@Component({
  selector: 'app-search-date',
  templateUrl: './search-date.component.html',
  styleUrls: ['./search-date.component.scss']
})
export class SearchDateComponent implements
  OnInit {
  @Input() tip = '';
  @Output() searchTime = new EventEmitter<Object>();
  error: boolean = false;

  // FormGroup
  searchForm!: FormGroup;

  isRangeValid = false; // 日期範圍檢查
  date = new Date; // 取得當下時間，以便限定 Data Picker 的最大日期為當日

  // 權限確認
  curAuth: CurrentAuth = {
    canLogin: true,
    canManipulate: true,
    canSetChannel: true,
    canSendEmail: true,
    canSendCoin: true
  };

  constructor(private currentAuthService: CurAuthService) {
    this.searchTimeForm();
  }

  async ngOnInit(): Promise<void> {
    await this.getCurrentAuth();
    this.searchTimeForm();
  }

  /**
   * 取得個人權限
   */
  getCurrentAuth() {
    this.currentAuthService.onGetCurAuth()
      .then((response: any) => {
        this.curAuth = response;
      })
      .catch(() => {
        this.error = true;
      })

  }

  /**
   * 檢查查詢區間合理性
   */
  searchTimeForm() {
    this.searchForm = new FormGroup({
      'start': new FormControl(null, Validators.required),
      'end': new FormControl(null, Validators.required)
    })

    this.searchForm.valueChanges.subscribe(
      (value: any) => {
        if (value.start > value.end) {
          // Error: 起始 > 結束日期
          this.isRangeValid = false;
        } else {
          // Success
          this.isRangeValid = true;
        }
      }
    )
  }

  /**
   * 將值 Output 至父元件
   */
  onSubmit() {
    const date = {
      start: this.searchForm.value.start,
      end: this.searchForm.value.end
    }

    this.searchTime.emit(date);
  }
}
