import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

import { ParamsService } from '../../params.service';

@Component({
  selector: 'app-sell-result-modal',
  templateUrl: './sell-result-modal.component.html',
  styleUrls: ['./sell-result-modal.component.scss']
})
export class SellResultModalComponent implements OnInit {
  public QrCode: string = '';

  constructor(
    public modalRef: MdbModalRef<SellResultModalComponent>,
    private paramsService: ParamsService) {

  }

  ngOnInit(): void {
    this.QrCode = this.paramsService.getParams();
  }

}
