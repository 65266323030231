import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'bitWise'
})

export class BitWisePipe implements PipeTransform {
  transform(roleAuth: number, permission: number) {
    if (( roleAuth & permission) == permission) {
      return 'V'
    } else {
      return ''
    }
  }
}
