import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'toFixed'
})

export class ToFixedPipe implements PipeTransform {
  transform(value: number) {
    return Math.floor(value)
  }
}
