import { Component, HostListener, OnInit, EventEmitter, Output } from '@angular/core';
import { SocialAuthService } from 'angularx-social-login';
import { AuthenticationService } from '../shared/services/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() drawerControl = new EventEmitter<boolean>();

  public curWidth: number = 0; // 視窗寬度
  drawerToggle: boolean = false; // RWD 控制 drawer

  constructor(
    private authenticationService: AuthenticationService,
    private socialAuthService: SocialAuthService
  ) { }

  ngOnInit(): void {
    this.curWidth = window.innerWidth;
  }

  // 監聽 window 寬度
  @HostListener('window:resize', ['$event']) onResize() {
    this.curWidth = window.innerWidth;
  }

  /**
   * 點擊後登出
   */
  onLogout() {
    this.authenticationService.logout();
    this.FBsignOut();
  }

  /**
   * FB 登出
   */
  FBsignOut(): void {
    this.socialAuthService.signOut().then().catch(() => {
      console.log('FB根本沒登入過喔！');
    }) ;
  }

  /**
   * 切換日夜間模式
   */
  toggleDarkTheme(): void {

    // 更換夜間模式按鈕圖
    let imgSrc =
    ($('.header__mode').attr('src') ===
    'assets/image/Diamondonline_personalbusiness_moon_80x80.png') ?
    'assets/image/Diamondonline_personalbusiness_sun_80x80.png'  :
    'assets/image/Diamondonline_personalbusiness_moon_80x80.png' ;

    $('.header__mode').attr("src", imgSrc);

    $('app-root').toggleClass('dark-theme');
  }

  /**
   * 控制 drawer 開關
   */
  onBack() {
    this.drawerToggle = !this.drawerToggle;

    this.drawerControl.emit(this.drawerToggle);
  }

  /**
   * 關閉 drawer
   */
  closeBack() {
    this.drawerToggle = false;
    this.drawerControl.emit(this.drawerToggle);
  }
}

