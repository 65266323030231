import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })

export class GetTimeService {
  // 將 API 送出的日期轉為 ISO，end 須處理成當日 23:59:59

  // Out put: 'YYYY-MM-01T00:00:00.000'
  transferStart(start?: string) {
    if(start){
      return new Date(start).toISOString()
    } else {
      // 無 start 則預設當月1號零點
      return new Date()
      .toISOString()
      .slice(0, 8)
      + '01T00:00:00.000+08:00';
    }
  }

  // Out put: 'YYYY-MM-DDT23:59:59.999'
  transferEnd(end?: string) {
    if(end) {
      return new Date(end)
      .toISOString()
      .slice(0, 10)
      + 'T23:59:59.999+08:00';
    } else {
      // 無 end 則預設今日午夜
      return new Date()
      .toISOString()
      .slice(0, 10)
      + 'T23:59:59.999+08:00';
    }
  }

}
