<app-error-alert *ngIf="error"></app-error-alert>

<app-loading-spinner *ngIf="isLoading && !error"></app-loading-spinner>

<main *ngIf="!isLoading && !error" [@expandOnEnter]>
  <div class="tab">
    <!-- <button [disabled]="gaId.length < 1" class="btn btn--request" (click)="openGoalSettingModal()">分成比／業績設定</button> -->
    <button [disabled]="gaId.length < 1" class="btn btn--request" (click)="openSendGaCoinModal()">派發體驗金</button>
    <button class="btn btn--request" (click)="openAddBossModal()">新增總代</button>

    <!-- RWD 全選／取消 -->
    <button *ngIf="!none && curWidth < 576" class="btn btn--all" (click)="checkAll()">
      <i class="far fa-check-square"></i>
      全選
    </button>
    <button *ngIf="none && curWidth < 576" class="btn btn--all" (click)="cancelAll()">
      取消&nbsp;({{ gaId.length }})
    </button>
  </div>

  <pagination-controls *ngIf="gas.length > items" (pageChange)="p = $event" previousLabel="上一頁" nextLabel="下一頁">
  </pagination-controls>

  <!-- 桌面板 table -->

  <div *ngIf="gas.length">
    <table class="table table-hover" *ngIf="curWidth > 576; else RWD">
      <thead>
        <tr>
          <th scope="col" *ngIf="!none" (click)="checkAll()" class="pointer">
            <i class="far fa-check-square"></i>
            全選
          </th>
          <th scope="col" *ngIf="none" (click)="cancelAll()" class="pointer underline">
            取消&nbsp;({{ gaId.length }})
          </th>
          <th scope="col">編號</th>
          <th scope="col">暱稱</th>
          <th scope="col">加入日期</th>
          <th scope="col">最後登入日期</th>
          <th scope="col">目標業績</th>
          <th scope="col">已達成業績</th>
          <th scope="col">已派發體驗金</th>
          <th scope="col">持有鑽幣</th>
          <th scope="col">達成率</th>
          <th scope="col">代理數</th>
          <th scope="col">會員數</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let ga of gas |
        paginate: {
          itemsPerPage: items,
          currentPage: p
        };
        let i = index">
          <td>
            <input [id]="i" type="checkbox" [checked]="gaId.includes(ga.userId)? true : false" [value]="ga.userId"
              (change)="toggleGaId($event)">
          </td>
          <td>
            <label [for]="i">
              {{ (p-1) * items + (i+1) }}
            </label>
          </td>
          <td>
            <label [for]="i">
              {{ ga.dNickname }}
            </label>
          </td>
          <td>
            <label [for]="i">
              {{ ga.joined | convertISO }}
            </label>
          </td>
          <td>
            <label [for]="i">
              {{ ga.lastLogin | convertISO }}
            </label>
          </td>
          <td>
            <label [for]="i">
              {{ ga.targetAchievement | number }}
            </label>
          </td>
          <td>
            <label [for]="i">
              {{ ga.currentAchievement | currency: '':'symbol':'1.0-0' }}
            </label>
          </td>
          <td>
            <label [for]="i">
              {{ ga.sentTrialDCoin | number }}
            </label>
          </td>
          <td>
            <label [for]="i">
              {{ ga.currentDCoin | number }}
            </label>
          </td>
          <td>
            <label [for]="i">
              {{ ga.doneRate | toFixed }}%
            </label>
          </td>
          <td>
            <label [for]="i">
              {{ ga.agentCount | number }}
            </label>
          </td>
          <td>
            <label [for]="i">
              {{ ga.memberCount | number }}
            </label>
          </td>
        </tr>
      </tbody>
    </table>


    <!-- 手機板 table -->
    <ng-template #RWD>
      <table class="table table-vertical table-striped" *ngFor="let ga of gas |
        paginate: {
          itemsPerPage: items,
          currentPage: p
        };
        let i = index">
        <tbody>
          <tr>
            <td>編號（{{ (p-1) * items + (i+1) }}）</td>
            <td>
              <input [id]="i" type="checkbox" [value]="ga.userId" [checked]="gaId.includes(ga.userId)? true : false"
                (change)="toggleGaId($event)">
            </td>
          </tr>
          <tr>
            <td>暱稱</td>
            <td>{{ ga.dNickname }}</td>
          </tr>
          <tr>
            <td>加入日期</td>
            <td>{{ ga.joined | convertISO }}</td>
          </tr>
          <tr>
            <td>最後登入日期</td>
            <td>{{ ga.lastLogin | convertISO }}</td>
          </tr>
          <tr>
              <td>目標業績</td>
              <td>{{ ga.targetAchievement | number }}</td>
            </tr>
            <tr>
              <td>已達成業績</td>
              <td>{{ ga.currentAchievement | currency: '':'symbol':'1.0-0' }}</td>
            </tr>
          <tr>
            <td>已派發體驗金</td>
            <td>{{ ga.sentTrialDCoin | number }}</td>
          </tr>
          <tr>
            <td>持有鑽幣</td>
            <td>{{ ga.currentDCoin | number }}</td>
          </tr>
          <tr>
              <td>達成率</td>
              <td>{{ ga.doneRate | toFixed }}%</td>
            </tr>
          <tr>
            <td>代理數</td>
            <td>{{ ga.agentCount | number }}</td>
          </tr>
          <tr>
            <td>會員數</td>
            <td>{{ ga.memberCount | number }}</td>
          </tr>
        </tbody>
      </table>
    </ng-template>

  </div>
</main>

<!-- 無資料狀態 -->
<app-no-info *ngIf="!gas.length && !isLoading && !error"></app-no-info>