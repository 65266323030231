import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { FormGroup, FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-achievement-modal',
  templateUrl: './achievement-modal.component.html',
  styleUrls: ['./achievement-modal.component.scss']
})
export class AchievementModalComponent implements OnInit {
  achievementForm!: FormGroup;

  constructor(
    public modalRef: MdbModalRef<AchievementModalComponent>,
    private http: HttpClient) { }

  ngOnInit(): void {
    this.achievementForm = new FormGroup({
      'percentOfAchievement': new FormControl(null, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
        Validators.pattern(/^[0-9]+(.[0-9]{0,1})?$/)
      ]),
      'targetAchievement': new FormControl(null, [
        Validators.required,
        Validators.min(0),
        Validators.pattern("^[0-9]*$")
      ])
    })
  }

  // 送出表單、打 API
  onSubmit() {
    console.log(this.achievementForm.value);
  }

}
