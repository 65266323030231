import { Component, HostListener, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';

import { PermissionChangeModalComponent } from './permission-change-modal/permission-change-modal.component';
import { RoleChangeModalComponent } from './role-change-modal/role-change-modal.component';

// Enviroment
import { environment } from 'src/environments/environment';

// Model
import { Operations } from 'src/app/models/operations.model';
import { CheckboxService } from 'src/app/shared/services/checkbox.service';

// Animation
import { expandOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-permission-setting',
  templateUrl: './permission-setting.component.html',
  styleUrls: ['./permission-setting.component.scss'],
  animations: [
    expandOnEnterAnimation()
  ]
})
export class PermissionSettingComponent implements OnInit {
  modalRef: MdbModalRef<PermissionChangeModalComponent> | null = null;
  isLoading: boolean = false; // 載入狀態
  error: boolean = false; // 錯誤
  curWidth: number = 0; // 視窗寬度
  p: number = 1 // 目前分頁
  items: number = 10;  // 單頁項目數量


  searchInput = ''; // 搜尋框的值

  operations: Operations[] = []; // 所有總代跟代理
  operationId: number[] = []; // 被勾選的 dUid
  none = false; // false: '全選'、true: '取消'

  constructor(
    private modalService: MdbModalService,
    private http: HttpClient,
    private checkboxService: CheckboxService) { }

  ngOnInit(): void {
    // 依據 width 決定渲染電腦版 or 手機板
    this.curWidth = window.innerWidth;

    // 載入中
    this.isLoading = true;

    // 載入所有總代與代理
    this.http
    .get(`${environment.SellsServer}/api/Operations`)
    .subscribe({
      next: (response: any) => {
        // 回傳成功，停止載入、刷新資料
        setTimeout(() => {
          this.isLoading = false;
          this.fetchData(response as Operations[]);
        }, 500)
      },
      error: (err: any) => {
        // 回傳失敗，停止載入、顯示 fail
        setTimeout(() => {
          this.isLoading = false;
          this.error = true;
          console.log(err)
        }, 3000)
      }
    })
  }

  // 監聽 window 寬度
  @HostListener('window:resize', ['$event']) onResize() {
    this.curWidth = window.innerWidth;
  }

  openPermissionModal() {
    this.modalRef = this.modalService.open(PermissionChangeModalComponent, {
      modalClass: 'modal-dialog-centered'
    });
  }

  openIndentityModal() {
    this.modalRef = this.modalService.open(RoleChangeModalComponent, {
      modalClass: 'modal-dialog-centered'
    });
  }

  fetchData(data: Operations[]) {
    this.operations = data;
  }

  /**
   * checkbox 勾選／取消勾選
   * */
  toggleOperationId(event: Event) {
    let id = parseInt((<HTMLInputElement>event.target).value, 10);
    this.checkboxService.isChecked(this.operationId, id);

    // 將 gaId 輸出至 service
    this.checkboxService.sendGaIdList(this.operationId);
  }

  checkAll() {
    // display '取消'
    this.none = true;
    this.checkboxService.allOperations(this.operations ,this.operationId, this.items);

    // 將 gaId 輸出至 service
    this.checkboxService.sendGaIdList(this.operationId);
  }

  cancelAll() {
    // display '全選'
    this.none = false;
    this.operationId = [];
  }

  /**
   * 變更搜尋關鍵字時清除原已選取項目，並強制返回第一頁
   * @param event
   */
  onSearchChange() {
    this.operationId = [];
    this.p = 1;
  }

  /**
   * 清除搜尋關鍵字
   */
  clear() {
    this.searchInput = '';
  }
}
