import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

import { AuthenticationService } from '../shared/services/authentication.service';
import { routerPermission } from '../models/role-permission.model'
import { MatSidenavContainer } from '@angular/material/sidenav';
import { UserIdleService } from 'angular-user-idle';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild(MatSidenavContainer) sidenavContainer!: MatSidenavContainer;
  curWidth: number = 0; // 視窗寬度
  pageTitle?: string; // 分頁名稱
  isOpen: boolean = true; // sidebar 收闔狀態
  isLoading = true; // 載入中

  // 權限控管
  permissionData: routerPermission = {};
  isPermit?: boolean;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private userIdle: UserIdleService
  ) {

    // 分頁標題切換
    this.router.events
      .pipe(filter((routerEvent) => routerEvent instanceof ActivationEnd))
      .subscribe((routerEvent: ActivationEnd | any) => {
        const data = routerEvent.snapshot.data;
        if (data?.title) {
          this.pageTitle = data.title;
        }

        // 當前路由造訪權許可
        this.routePermission();
      });
  }

  async ngOnInit(): Promise<void> {
    // 路由造訪權限
    await this.getRoutePermission();
    await this.routePermission();

    // 根據視窗寬度決定 sidenav 是否展開
    this.curWidth = window.innerWidth;

    if (this.curWidth <= 992) {
      this.isOpen = false;
    } else {
      this.isOpen = true;
    }

    //Start watching for user inactivity.
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => console.log(count));

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      setTimeout(() => {
        this.isLoading = true;
        setTimeout(() => {
          this.authenticationService.logout();
        }, 0)
      }, 1000)
    })
  }

  // 監聽 window 寬度
  @HostListener('window:resize', ['$event']) onResize() {
    this.curWidth = window.innerWidth;

    if (this.curWidth <= 992) {
      this.isOpen = false;
    } else {
      this.isOpen = true;
    }
  }

  /**
   * 取得所有路由造訪權限並存入permissionData
   */
  async getRoutePermission() {
    let token = localStorage.getItem('token');
    if (token) {
      await this.authenticationService.rolePermission().then((response: any) => {
        this.permissionData = response;
        this.isLoading = false;
        console.log("初始造訪權限資料GET！");
      }).catch(() => {
        console.log("接不到Service造訪權限的資料欸哭哭喔QQ");
      })
    }
  }

  /**
   * 當前URL及路由造訪權決定是否渲染模板
   * @returns
   */
  async routePermission() {
    let url = this.router.url;
    try {
      url = url.split('/dashboard/')[1].split("/" || "'")[0];
    } catch {
      console.log(`取得當前URL：${url}，但不符合split規則喔！`)
      return;
    }

    this.isPermit = this.permissionData[url];
  }

  /**
   * drawer control
   * @param drawerToggle
   */
  drawerControl(drawerToggle: boolean) {
    this.isOpen = drawerToggle;
  }
}
