import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from "@angular/common/http";
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';


import { CheckboxService } from 'src/app/shared/services/checkbox.service';

import { environment } from 'src/environments/environment';
import { ReloadService } from 'src/app/shared/services/reload.service';

@Component({
  selector: 'app-goal-setting-modal',
  templateUrl: './goal-setting-modal.component.html',
  styleUrls: ['./goal-setting-modal.component.scss']
})
export class GoalSettingModalComponent implements OnInit {
  goalSettingForm!: FormGroup;
  target = 0; // 業績目標，預設0

  // button 狀態管理
  isStoring: boolean = false;
  state: string = '儲存';

  constructor(
    public modalRef: MdbModalRef<GoalSettingModalComponent>,
    private http: HttpClient,
    private checkboxService: CheckboxService,
    private reloadService: ReloadService
  ) {}

  ngOnInit(): void {

    this.goalSettingForm = new FormGroup({
      'basicSalary': new FormControl(null, [
        Validators.required,
        Validators.pattern("^[0-9]*$")
      ]),
      'salaryMultiple': new FormControl(20, Validators.required),
      'percentOfAchievement': new FormControl(null, [
        Validators.required,
        Validators.max(100)
      ]),
      'dCoinDiscount': new FormControl(null, [
        Validators.required,
        Validators.max(99)
      ])
    });

    // 即時更新業績目標的值
    this.goalSettingForm.valueChanges.subscribe(
      ((value: any) => this.target = value.basicSalary * value.salaryMultiple)
    )
  }

  // 送出表單、打 API
  onSubmit() {
    const value = this.goalSettingForm.value;

    const body = {
      'gaIdList': this.checkboxService.getGaIdList(),
      'basicSalary': value.basicSalary,
      'salaryMultiple': value.salaryMultiple,
      'percentOfAchievement': value.percentOfAchievement,
      'dCoinDiscount': value.dCoinDiscount
    }

    // 開始 Call API
    // Showing spinner & State change
    this.isStoring = true;
    this.state = '儲存中';

    this.http
      .put(`${environment.SellsServer}/api/Operations/edit/ga/achievement`, body)
      .subscribe(
        () => {
          // 成功
          setTimeout(() => {
            this.isStoring = false;
            this.state = '儲存成功 Ｏ';

            setTimeout(() => {
              // 1.5 秒後關閉 modal 並重刷原頁面
              this.modalRef.close();
              // this.reloadService.reload();
              this.reloadService.redirectTo('business-manage/ga-setting');
            }, 1500)

          }, 1000)
        },
        () => {
          // 失敗
          setTimeout(() => {
            this.isStoring = false;
            this.state = '更改失敗 Ｘ'

            setTimeout(() => {
              this.state = '儲存';
            }, 2000)

          }, 1000)
        })
  }
}
