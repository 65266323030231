<div class="wrap">

  <div class="modal-header">
    <h2 id="exampleModalLabel" class="modal-title">
      分成比／業績設定</h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
  </div>

  <div class="modal-body">

    <form
    [formGroup]="goalSettingForm"
    (ngSubmit)="onSubmit()"
    class="form"
    ngNativeValidate>

            <div class="form__input--group">
              <label class="form__label" for="basicSalary">底薪</label>
              <div>
                <input
                formControlName="basicSalary"

                class="form__input"
                type="number"
                id="basicSalary"
                min="0">
                <span class="form__unit">元</span>
              </div>
            </div>


            <div class="form__input--group">
              <label class="form__label" for="salaryMultiple">業績倍數</label>
              <div>
                <select
                formControlName="salaryMultiple"

                class="form__input"
                id="salaryMultiple">
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="30">30</option>
                <option value="35">35</option>
                <option value="40">40</option>
                <option value="45">45</option>
                <option value="50">50</option>
                </select>
                <span class="form__unit">倍</span>
              </div>
            </div>

            <div class="form__input--group">
              <label class="form__label" for="target">業績目標</label>
              <div class="form__select">
                <input
                [placeholder]="target"

                class="form__input"
                type="number"
                id="target"
                min="0"
                disabled="true">
                <span class="form__unit">元</span>
              </div>
            </div>

            <div class="form__input--group">
              <label class="form__label" for="percentOfAchievement">業績達成後分成</label>
            <div>
                <input
                formControlName="percentOfAchievement"

                class="form__input"
                type="number"
                step=".1"
                id="percentOfAchievement"
                min="0">
                <span class="form__unit">％</span>
              </div>
            </div>

            <div class="form__input--group">
              <label class="form__label" for="dCoinDiscount">鑽幣折扣</label>
              <div>
                <input
                formControlName="dCoinDiscount"

                class="form__input"
                type="number"
                id="dCoinDiscount"
                min="0">
                <span class="form__unit">％</span>
              </div>
            </div>

      <button
      class="btn btn--request"
      type="submit"
      [disabled]="state !== '儲存' || !goalSettingForm.valid"
      >
        <span
          *ngIf="isStoring"
          class="spinner-border spinner-border-sm"
        ></span>
        {{ state }}
      </button>

    </form>

  </div>

</div>
