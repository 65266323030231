import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { expandOnEnterAnimation } from 'angular-animations';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';

// Module
import { CurrentAuth } from 'src/app/models/currentAuth.model';
import { Agent } from 'src/app/models/agent';

// Modal
import { MailModalComponent } from './mail-modal/mail-modal.component';
import { AchievementModalComponent } from './achievement-modal/achievement-modal.component';
import { SendDcoinModalComponent } from './send-dcoin-modal/send-dcoin-modal.component';

// Service
import { CheckboxService } from 'src/app/shared/services/checkbox.service';
import { CurAuthService } from 'src/app/shared/services/curAuth.service';
import { AgentManageService } from './agent-manage.service';

@Component({
  selector: 'app-agent-manage',
  templateUrl: './agent-manage.component.html',
  styleUrls: ['./agent-manage.component.scss'],
  animations: [
    expandOnEnterAnimation()
  ]
})
export class AgentManageComponent implements OnInit {
  modalRef: MdbModalRef<MailModalComponent> | null = null;
  isLoading: boolean = false; // 載入狀態
  error: boolean = false; // 錯誤
  curWidth: number = 0; // 視窗寬度
  p: number = 1; // 目前分頁
  items: number = 10;  // 單頁項目數量
  tipContent: string = '查詢選擇日期內的體驗金額度派發／業績資訊，預設顯示當月資訊。' // 提示字元
  ghost: number = 0; // 幽靈人口數


  // 權限確認
  curAuth: CurrentAuth = {
    canLogin: true,
    canManipulate: true,
    canSetChannel: true,
    canSendEmail: true,
    canSendCoin: true
  };

  searchInput = ''; // 搜尋框的值

  agents: Agent[] = []; // agent array
  agentId: number[] = []; // 被勾選的 dUid
  selected: string = ''; // 選定之操作
  none = false; // false: '全選'、true: '取消'

  // FormGroup
  searchForm!: FormGroup;

  constructor(
    private modalService: MdbModalService,
    private checkboxService: CheckboxService,
    private currentAuthService: CurAuthService,
    private agentManageService: AgentManageService) { }

  async ngOnInit(): Promise<void> {
    // 依據 width 決定渲染電腦版 or 手機板
    this.curWidth = window.innerWidth;

    await this.getCurAuth();
    this.getAgents('');
  }

  // 監聽 window 寬度
  @HostListener('window:resize', ['$event']) onResize() {
    this.curWidth = window.innerWidth;
  }


  openMailModal() {
    this.modalRef = this.modalService.open(MailModalComponent, {
      modalClass: 'modal-dialog-centered',
    });
  }

  openAchievementModal() {
    this.modalRef = this.modalService.open(AchievementModalComponent, {
      modalClass: 'modal-dialog-centered',
    });
  }

  openSendDcoinModal() {
    this.modalRef = this.modalService.open(SendDcoinModalComponent, {
      modalClass: 'modal-dialog-centered',
    });
  }

  /**
   * 取得目前的使用者權限
   */
  async getCurAuth() {
    this.isLoading = true;

    await this.currentAuthService.onGetCurAuth()
      .then((response: any) => {
        this.curAuth = response;
        // console.log(this.curAuth);
      })
      .catch(() => {
        this.error = true;
      })
  }

  /**
   * 取得代理列表
   */
  getAgents(date: any) {
    // 載入中
    this.isLoading = true;

    // 如果在選單狀態中重新查詢，由於 select、checkbox 都沒有偵測到 change，則 agentId、option 皆會留存造成 Bug，需清空處理
    this.agentId = [];
    this.selected = '';

    // 打 API
    this.agentManageService
      .getAgents(date.start, date.end)
      .subscribe({
        next: (response: any) => {
          // 成功，停止載入並刷新資料
          setTimeout(() => {
            this.isLoading = false;
            this.agents = response;
            this.ghost = this.agents.filter(i => i.isGhost === true).length
          }, 300)
        },
        error: (err: any) => {
          // 失敗，停止載入並顯示錯誤訊息
          setTimeout(() => {
            this.isLoading = false;
            this.error = true;
            console.log(err);
          }, 1000)
        }
      })
  }

  // checkbox 勾選／取消勾選
  toggleAgentId(event: Event) {
    let id = parseInt((<HTMLInputElement>event.target).value, 10);
    this.checkboxService.isChecked(this.agentId, id);

    // 將 agentId 輸出至 service
    this.checkboxService.sendAgentIdList(this.agentId);
  }

  // select 的選取項目（會依據項目顯示可供勾選的 checkbox）
  selectAction(event: any) {
    // 切換 option 時清空原 option 已勾選的 agentId，否則遺留的 Id 會造成 Bug
    this.agentId = [];
    this.none = false;
    this.selected = event.target.value;
  }

  checkAll() {
    // display '取消'
    this.none = true;
    this.checkboxService.allAgents(this.agents, this.agentId, this.selected, this.items);

    // 將 agentId 輸出至 service
    this.checkboxService.sendAgentIdList(this.agentId);
  }

  cancelAll() {
    // display '全選'
    this.none = false;
    this.agentId = [];
  }

  /**
   * 變更搜尋關鍵字時清除原已選取項目，並強制返回第一頁
   * @param event
   */
  onSearchChange() {
    this.agentId = [];
    this.p = 1;
  }

  /**
   * 清除搜尋關鍵字
   */
  clear() {
    this.searchInput = '';
  }
}
