import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';


import { environment } from 'src/environments/environment';
import { FBPlayerLoginInfo,  LinePlayerLoginInfo, GooglePlayerLoginInfo, ApplePlayerLoginInfo } from '../models/social-login-info.model';
import { SocialAuthService, SocialUser, FacebookLoginProvider } from 'angularx-social-login';

// Service
import { AuthenticationService } from '../shared/services/authentication.service';

// declare var FB: any
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit {
  // NgForm 設定
  @ViewChild('f', { static: false })
  loginForm!: NgForm;
  socialUser!: SocialUser;
  loggedIn?: boolean;

  // import axios
  axios = require('axios');

  // 帳號密碼驗證
  errorMessage: string = '';

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private socialAuthService: SocialAuthService
  ) { }


  ngOnInit(): void {
    // 登入狀態直接進入系統
    if (this.authenticationService.isUserLoggedIn()) {
      this.router.navigate(['dashboard/account']);
    }

    // 第三方驗證返回後執行登入
    if (this.router.url.includes(`code=`) || this.router.url.includes(`access_token=`)) {
      this.LineLogin();
      this.GoogleLogin();
      this.AppleLogin();
    }
  }

  // typing 時不顯示 error message
  onFocus(){
    this.errorMessage = '';
  }

  // 點擊登入後呼叫 API
  onLogging() {
    let username = this.loginForm.value.username;
    let password = this.loginForm.value.password;

    //* 改成登入驗證services
    this.authenticationService.login(username, password)
    .then((response: any) => {
      // 登入成功導向帳號資訊頁
      this.router.navigate(['dashboard/account']);
    })
    .catch((err: any) => {
      // 使用者登入失敗，顯示下列訊息並清空欄位
      this.errorMessage = '帳號或密碼輸入錯誤';
      console.log(err);
      this.loginForm.reset();
    });
  }

  /**
   *  第三方驗證：FB 驗證並登入
   */
  async loginFB() {
    await this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
    this.FBLogin();
  }

  /**
   * FB 登入
   */
  async FBLogin() {
    this.socialAuthService.authState.subscribe((user) => {
      this.socialUser = user;
      this.loggedIn = (user != null);
    });
    

    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
      }
    }

    await this.axios.post(`${environment.LoginServer}/Users/FB/Authenticate`, `"${this.socialUser.authToken}"`, axiosConfig)
    .then((response: FBPlayerLoginInfo) => {

      if (response.data.code === 0) {
        // FB驗證登入通過後，存取token再送業務系統的登入API
        this.socialLogin(response.data.token);

      } else {
        this.errorMessage = `FB登入失敗 (錯誤代碼：${response.data.code})`;
      }
    })
    .catch( (error: any) => {
      // 使用者登入失敗
      this.errorMessage = `FB登入失敗 (狀態碼：${error.response['status']})`;
      console.log(error.response);
    });

    this.router.navigate(['login']);
  }

  /**
   * 第三方驗證：LINE 驗證
   */
  loginLine() {
    const LineUri = environment.SocialLoginURL;
    const encoded = encodeURIComponent(LineUri);
    const clientID = environment.LineChannelID;

    window.location.href =
      'https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=' + clientID + '&redirect_uri=' + encoded
      + '&state=12345abcde&scope=profile';
  }

  /**
   * LINE 驗證後登入
   */
  async LineLogin() {
    console.log('LineLogin開始');
    if (this.router.url.split(`code=`)[1] !== undefined && this.router.url.includes(`state`)) {
      let LineLoginUrl = this.router.url.split(`code=`)[1];
      const LineCode = LineLoginUrl.split(`&`)[0];

      await this.axios.post(`${environment.LoginServer}/Users/Line/Authenticate`, { Code: LineCode, redirectUri: environment.SocialLoginURL })
      .then((response: LinePlayerLoginInfo) => {

        if (response.data.code === 0) {
          console.log('LINE成功到這裡');
          // LINE驗證登入通過後，存取token再送業務系統的登入API
          this.socialLogin(response.data.token);

        } else {
          console.log('LINE失敗');
          console.log(response);
          this.errorMessage = `LINE登入失敗 (錯誤代碼：${response.data.code})`;
        }
      })
      .catch( (error: any) => {
        // 使用者登入失敗
        this.errorMessage = `LINE登入失敗 (狀態碼：${error.response['status']})`;
        console.log(error.response);
      });

      this.router.navigate(['login']);
    }
  }

  /**
   * 第三方驗證：GOOGLE 驗證
   */
  loginGoogle() {
    let url = encodeURIComponent(environment.SocialLoginURL);
    const GoogleclientID = environment.GoogleChannelID;
    window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?' +
      'client_id=' + GoogleclientID +
      'response_type=token&' +
      'redirect_uri=' + url + '&' +
      'prompt=' + encodeURIComponent('select_account consent') + '&' +
      'scope=openid';
  }

  /**
   * GOOGLE 驗證後登入
   */
  async GoogleLogin() {
    console.log('GoogleLogin開始');
    if (this.router.url.split(`access_token=`)[1] !== undefined) {
      console.log('GOOGLE進到URL ifelse');
      let GoogoleUrl = this.router.url.split(`access_token=`)[1];
      const GoogleToken = GoogoleUrl.split(`&token_type`)[0];

      this.axios.post(`${environment.LoginServer}/Users/Google/Authenticate`, { AccessToken: GoogleToken })
      .then((response: GooglePlayerLoginInfo) => {

        if (response.data.code == 0) {
          console.log('GOOGLE成功到這裡');

          // GOOGLE驗證登入通過後，存取token再送業務系統的登入API
          this.socialLogin(response.data.token);


        } else {
          this.errorMessage = `GOOGLE登入失敗 (錯誤代碼：${response.data.code})`;
          console.log('GOOGLE失敗');
          console.log(response);
        }
      })
      .catch( (error: any) => {
        // 使用者登入失敗
        this.errorMessage = `GOOGLE登入失敗 (狀態碼：${error.response['status']})`;
        console.log(error.response);
      });

      this.router.navigate(['login']);
    }
  }

  /**
   * 第三方驗證：APPLE 驗證
   */
  loginApple() {
    const AppleUri = environment.SocialLoginURL;
    const encoded = encodeURIComponent(AppleUri);
    const clientID = environment.AppleChannelID;

    window.location.href = 'https://appleid.apple.com/auth/authorize?' +
      'client_id=' + clientID + '&' +
      'response_type=code&' +
      'redirect_uri=' + encoded;
  }

  /**
   * APPLE 驗證後登入
   */
  async AppleLogin() {

    if (this.router.url.split(`code=`)[1] !== undefined && !this.router.url.includes(`state`)) {
      const AppleCode = this.router.url.split(`code=`)[1];

      await this.axios.post(`${environment.LoginServer}/Users/Apple/Authenticate`, { AccessToken: AppleCode, redirectUri: environment.SocialLoginURL })
      .then((response: ApplePlayerLoginInfo) => {

        if (response.data.code === 0) {
          // APPLE驗證登入通過後，存取token再送業務系統的登入API
          this.socialLogin(response.data.token);

        } else {
          this.errorMessage = `APPLE登入失敗 (錯誤代碼：${response.data.code})`;
        }
      })
      .catch( (error: any) => {
        // 使用者登入失敗
        this.errorMessage = `APPLE登入失敗 (狀態碼：${error.response['status']})`;
        console.log(error.response);
      });

      this.router.navigate(['login']);
    }
  }


  /**
   * 第三方驗證後取token，再送業務系統登入驗證
   * @param {string} socialToken 第三方回傳之token
   */
  async socialLogin(socialToken: string) {
    console.log('準備好過業務系統自己的驗證了嘛！？大聲點我聽不見！');
    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
      }
    }

    await this.axios.post(`${environment.SellsServer}/api/Account/tokenlogin`, `"${socialToken}"`, axiosConfig)
      .then((response: any) => {
        // 使用者登入成功，將登入成功取得的 token 存入 browser local storage 中
        localStorage.setItem('token', response.data.token);
        console.log('成功得到Token欸好感動我先哭著存一波！');

        this.router.navigate(['dashboard/account']);
      })
      .catch( (error: any) => {
        // 使用者登入失敗，顯示下列訊息並清空欄位
        this.errorMessage =  `業務系統登入失敗，請洽營運人員 (${error.response.data})。`;
        console.log('Token失敗啦！（尖叫');
        console.log(error.response);
      });
    }
}
