import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

// Environment
import { environment } from 'src/environments/environment';

// Service
import { ReloadService } from 'src/app/shared/services/reload.service';
import { CheckboxService } from 'src/app/shared/services/checkbox.service';


@Component({
  selector: 'app-permission-change-modal',
  templateUrl: './permission-change-modal.component.html',
  styleUrls: ['./permission-change-modal.component.scss']
})
export class PermissionChangeModalComponent implements OnInit {
  @ViewChild('f', { static: false })
  permissionChangeForm!: NgForm;
  // button 狀態管理
  state: string = '儲存';
  isStoring: boolean = false;

  permissionGroup = {
    backstageLogin: '1',
    backstageOperates: '1',
    sellsetting: '1',
    sendEmail: '1',
    giveCoin: '1',
  }

  constructor(
    public modalRef: MdbModalRef<PermissionChangeModalComponent>,
    private http: HttpClient,
    private checkboxService: CheckboxService,
    private reloadService: ReloadService) { }

  ngOnInit(): void {
  }

  onSubmit() {

    // Showing spinner & State change
    this.isStoring = true;
    this.state = '儲存中';

    const value = this.permissionChangeForm.value;

    // 把 1 跟 0 拼成二進制表示法（Output: string, ex: '10100'）
    // 雖然 radio 的 value 和 permissionGroup 裡的變數都是 number，但到這裡時就變 string 了，將錯就錯
    const result: string =
      value.giveCoin +
      value.sendEmail +
      value.sellsetting +
      value.backstageOperates +
      value.backstageLogin

    // 將 result 轉成十進制，以符合 api 的 body 格式
    // Ex: 10100 = 20
    const body = {
      userIdList: this.checkboxService.getGaIdList(),
      auth: parseInt(result, 2)
    }

    this.http
      .put(`${environment.SellsServer}/api/Operations/edit/user/auth`, body)
      .subscribe({
        next: (response: any) => {
          setTimeout(() => {
            this.isStoring = false;
            this.state = '儲存成功';

            setTimeout(() => {
              this.modalRef.close();
              this.reloadService.redirectTo('business-manage/permission-setting')
            }, 1500)

          }, 1500)
        },
        error: (err: any) => {
          setTimeout(() => {
            this.isStoring = false;
            this.state = '儲存失敗';
            console.log(err);

            setTimeout(() => {
              this.state = '儲存';
            }, 2000)

          }, 1500)
        }
      })
  }
}
