<!-- 載入中 -->
<app-loading-spinner *ngIf="isLoading && !error"></app-loading-spinner>

<!-- 錯誤 -->
<app-error-alert *ngIf="error && !isLoading"></app-error-alert>

<!-- 無錯誤、非載入狀態 -->
<main *ngIf="!error && !isLoading" [@expandOnEnter]>
  <div class="d-flex justify-content-end">
    <button
    *ngIf="channels.length < 10 && curAuth.canSetChannel && curAuth.canManipulate"
    class="btn btn--request"
    (click)="openNewChannelModal()">
      <i class="far fa-plus-square"></i>
      &nbsp;新增管道
    </button>
  </div>

  <!-- Desktop -->
  <table *ngIf="curWidth > 576 && channels.length; else RWD" class="table table-hover">
    <thead>
      <tr>
        <th>序號</th>
        <th>管道名稱</th>
        <th>建立日期</th>
        <th>修改日期</th>
        <th colspan="2">連結</th>
        <th>QR Code</th>
        <th *ngIf="curAuth.canSetChannel && curAuth.canManipulate">設定</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let c of channels">
        <td>{{ c.seq }}</td>
        <td>{{ c.name }}</td>
      <td>{{ c.created | convertISO }}</td>
      <td>{{ c.lastModify | convertISO }}</td>
      <td>
        <a [href]="c.url" target="_new" title="點擊開啟新分頁" class="underline">
          {{ c.url | url:60 }}
        </a>
      </td>
      <td class="pointer" (click)="copyUrl(c.url)">
        <i class="far fa-copy"></i>&nbsp;複製
      </td>
      <td class="pointer" (click)="openModal(c.url)">
        <i class="fas fa-qrcode"></i>&nbsp;查看
      </td>
      <td
      *ngIf="curAuth.canSetChannel && curAuth.canManipulate"
      class="pointer"
      (click)="openEditModal(c.seq)"><i class="far fa-edit"></i>&nbsp;編輯</td>
      </tr>
    </tbody>
  </table>

  <!-- RWD -->
  <ng-template #RWD>
    <div *ngIf="channels.length">
      <table class="table table-vertical table-striped" *ngFor="let c of channels">
        <tbody>
          <tr>
            <td>序號</td>
            <td colspan="2">{{ c.seq }}</td>

          </tr>
          <tr>
            <td>管道名稱</td>
            <td colspan="2">{{ c.name }}</td>
          </tr>
          <tr>
            <td>建立日期</td>
            <td colspan="2">{{ c.created | convertISO }}</td>
          </tr>
          <tr>
            <td>修改日期</td>
            <td colspan="2">{{ c.lastModify | convertISO }}</td>
          </tr>
          <tr>
            <td>連結</td>
            <td>
              <a
              [href]="c.url"
              target="_new"
              class="underline">
              點此前往
              </a>
            </td>
            <td (click)="copyUrl(c.url)"><i class="far fa-copy"></i>複製</td>
          </tr>
          <tr>
            <td>QR Code</td>
            <td colspan="2" (click)="openModal(c.url)" class="underline">
              查看
            </td>
          </tr>
          <tr *ngIf="curAuth.canSetChannel && curAuth.canManipulate">
            <td>設定</td>
            <td class="underline" colspan="2" (click)="openEditModal(c.seq)">
              編輯
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-template>
</main>

<!-- 無資料狀態 -->
<app-no-info *ngIf="!channels.length && !isLoading && !error"></app-no-info>
