<div class="wrap">

  <div class="modal-header">

    <h4 id="exampleModalLabel" class="modal-title">
      編輯管道</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
  </div>

  <div class="modal-body">

    <form (ngSubmit)="onSubmit()" #f="ngForm" class="form" ngNativeValidate>

      <div class="form__input">
        <label for="editChannel">管道名稱</label>
        <input
        [(ngModel)]="editChannel"
        name="editChannel"

        type="text"
        id="editChannel"
        class="form-control"
        placeholder="字數限 16 個字元"
        maxlength="16"
        required />
      </div>

      <p>※管道名稱不可重複，編輯後該管道瀏覽次數歸零</p>

      <button
      class="btn btn--request"
      type="submit"
      [disabled]="state !== '確認' || editChannel.length == 0">
      <span
      *ngIf="isStoring"
      class="spinner-border spinner-border-sm">
      </span>
        {{ state }}
      </button>

    </form>


  </div>

</div>
