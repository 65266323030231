import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sell-manage',
  templateUrl: './sell-manage.component.html',
  styleUrls: ['./sell-manage.component.scss']
})
export class SellManageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
