import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })

export class RoleService {
  role: number = 0; //角色身份
  roleAuth: number = 0; //角色權限

  /**
   * 將選取的 Role number 存入
   */
  setRole(role: number, roleAuth: number) {
    this.role = role;
    this.roleAuth = roleAuth;
  }

  /**
   * 拿取 setRole 存入的 Role Number
   */
  getRole() {
    return this.role
  }

  /**
   * 拿取 setRole 存入的 Role Auth
   */
  getRoleAuth() {
    return this.roleAuth
  }
}
