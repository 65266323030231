<div class="wrap">

  <div class="modal-header">

    <h2 id="exampleModalLabel" class="modal-title">
      權限變更</h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
  </div>

  <div class="modal-body">

    <form
    (ngSubmit)="onSubmit()"
    #f="ngForm"
    class="form">

      <div class="form__input--group">
        <label class="form__label">後台登入</label>
        <div>
          <input
          [(ngModel)]="permissionGroup.backstageLogin"

          type="radio"
          value="1"
          name="backstageLogin"
          class="form__input"
          id="truebackstageLogin">
          <label for="truebackstageLogin">允許</label>
        </div>
        <div>
          <input
          [(ngModel)]="permissionGroup.backstageLogin"

          type="radio"
          value="0"
          name="backstageLogin"
          class="form__input"
          id="falsebackstageLogin">
          <label for="falsebackstageLogin">禁止</label>
        </div>
      </div>

      <div class="form__input--group">
        <label class="form__label">後台操作</label>
        <div>
          <input
          [(ngModel)]="permissionGroup.backstageOperates"

          type="radio"
          value="1"
          name="backstageOperates"
          class="form__input"
          id="truebackstageOperates">
          <label for="truebackstageOperates">允許</label>
        </div>
        <div>
          <input
          [(ngModel)]="permissionGroup.backstageOperates"

          type="radio"
          value="0"
          name="backstageOperates"
          class="form__input"
          id="falsebackstageOperates">
          <label for="falsebackstageOperates">禁止</label>
        </div>
      </div>

      <div class="form__input--group">
        <label class="form__label">推廣設置</label>
        <div>
          <input
          [(ngModel)]="permissionGroup.sellsetting"

          type="radio"
          value="1"
          name="sellsetting"
          class="form__input"
          id="trueSellsetting">
          <label for="trueSellsetting">允許</label>
        </div>
        <div>
          <input
          [(ngModel)]="permissionGroup.sellsetting"

          type="radio"
          value="0"
          name="sellsetting"
          class="form__input"
          id="falseSellsetting">
          <label for="falseSellsetting">禁止</label>
        </div>
      </div>

      <div class="form__input--group">
        <label class="form__label">發送確認信</label>
        <div>
          <input
          [(ngModel)]="permissionGroup.sendEmail"

          type="radio"
          value="1"
          name="sendEmail"
          class="form__input"
          id="trueSendEmail">
          <label for="trueSendEmail">允許</label>
        </div>
        <div>
          <input
          [(ngModel)]="permissionGroup.sendEmail"

          type="radio"
          value="0"
          name="sendEmail"
          class="form__input"
          id="falseSendEmail">
          <label for="falseSendEmail">禁止</label>
        </div>
      </div>

      <div class="form__input--group">
        <label class="form__label">派發體驗金／鑽幣</label>
        <div>
          <input
          [(ngModel)]="permissionGroup.giveCoin"

          type="radio"
          value="1"
          name="giveCoin"
          class="form__input"
          id="trueGiveCoin">
          <label for="trueGiveCoin">允許</label>
        </div>
        <div>
          <input
          [(ngModel)]="permissionGroup.giveCoin"

          type="radio"
          value="0"
          name="giveCoin"
          class="form__input"
          id="falseGiveCoin">
          <label for="falseGiveCoin">禁止</label>
        </div>
      </div>

      <button
      class="btn btn--request"
      type="submit"
      [disabled]="state !== '儲存' || this.f.invalid"
      (click)="onSubmit()">
      <span
      *ngIf="isStoring"
      class="spinner-border spinner-border-sm">
      </span>
        {{ state }}
      </button>

    </form>

  </div>

</div>
