import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from "@angular/common/http";
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

// Environment
import { environment } from 'src/environments/environment';

// Service
import { CheckboxService } from 'src/app/shared/services/checkbox.service';
import { ReloadService } from 'src/app/shared/services/reload.service';

@Component({
  selector: 'app-send-ga-coin-modal',
  templateUrl: './send-ga-coin-modal.component.html',
  styleUrls: ['./send-ga-coin-modal.component.scss']
})
export class SendGaCoinModalComponent implements OnInit {
  gaCoinForm!: FormGroup;
  // Button 狀態
  state: string = '確認';
  isStoring: boolean = false;

  // 超過持有數量
  error: boolean = false;
  errorMessage: string = '';
  regCheck = new RegExp(/^[0-9]*$/);

  constructor(
    public modalRef: MdbModalRef<SendGaCoinModalComponent>,
    private http: HttpClient,
    private checkboxService: CheckboxService,
    private reloadService: ReloadService
    ) { }

  ngOnInit(): void {
    this.gaCoinForm = new FormGroup({
      'amount': new FormControl(null, [
        Validators.required,
        Validators.min(1),
        Validators.pattern(/^[0-9]*$/)
      ]),
      'check': new FormControl(null, [
        Validators.requiredTrue
      ])
    })

    this.gaCoinForm.valueChanges.subscribe(
      (value => {
        const number = value.amount;
        if (number < 1) {
          // Error: 未達1元
          this.error = true;
          this.errorMessage = '金額需大於等於 1';
        } else if (!this.regCheck.test(number)) {
          // Error: 金額非正整數
          this.error = true;
          this.errorMessage = '請輸入整數';
        } else if (number > 100000000) {
          this.error = true;
          this.errorMessage = '超過每人每次可發送金額上限（1億）';
        } else {
          // Typing 不顯示錯誤訊息
          this.error = false;
        }
      })
    )

    this.gaCoinForm.controls['amount'].valueChanges.subscribe(() => {
      this.gaCoinForm.controls['check'].setValue(null)
    })
  }

  onSubmit() {
    const body = {
      toIdList: this.checkboxService.getGaIdList(),
      amount: this.gaCoinForm.value.amount
    }

    // console.log(body);

    // Showing spinner & State change
    this.isStoring = true;
    this.state = '發送中';

    // Make sure the form is VALID.
    if (this.gaCoinForm.status === 'VALID') {
      this.http
      .post(`${environment.SellsServer}/api/Operations/SendGaTrialDCoin`, body)
      .subscribe({
        // 成功
        next: (response: any) => {
          setTimeout(() => {
            this.isStoring = false;
            this.state = '發送成功';

            setTimeout(() => {
              this.modalRef.close();
              this.reloadService.redirectTo('business-manage/ga-setting');
            }, 1500)

          }, 1500)
        },
        error: (err: any) => {
          setTimeout(() => {
            this.isStoring = false;
            this.state = '發送失敗';
            console.log(err);

            setTimeout(() => {
              this.gaCoinForm.controls['amount'].reset();
              this.state = '確認';
            }, 1500)

          }, 1500)
        }
      })
    } else {
      this.error = true;
      this.errorMessage = '無效表單';
    }
  }

}
