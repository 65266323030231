<!-- 此帳號資訊 -->
<app-my-agent></app-my-agent>

<app-search-date
(searchTime)="getAgents($event)"
[tip]="tipContent"></app-search-date>

<!-- 載入中 -->
<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<!-- 錯誤 -->
<app-error-alert *ngIf="error && !isLoading"></app-error-alert>

<!-- 三分天下功能區 -->
<ng-container *ngIf="agents.length && !isLoading && !error">

  <section class="row m-0 d-md-flex justify-content-between align-items-center" [@expandOnEnter]>
    <!-- 選擇操作的選單 -->
    <div class="active col-md-3">

      <select (change)="selectAction($event)">
        <option value="default">*請選擇操作</option>
        <option [disabled]="!curAuth.canSendEmail || !curAuth.canManipulate" value="email">發送確認信件</option>
        <option [disabled]="!curAuth.canSendCoin || !curAuth.canManipulate" value="coin">體驗金／鑽幣分派</option>
      </select>

      <button
      *ngIf="selected === 'email'"
      class="btn btn--request" (click)="openMailModal()"
      [disabled]="!agentId.length">
        確認
      </button>

      <button
      *ngIf="selected === 'coin'"
      class="btn btn--request"
      (click)="openSendDcoinModal()"
      [disabled]="!agentId.length">
      確認
      </button>

      <ng-container *ngIf="(curWidth < 576) && (!selected || selected === 'default')">
        <div class="take--over"></div>
      </ng-container>
    </div>

    <!-- 分頁 -->
    <pagination-controls
      *ngIf="curWidth > 576 && agents.length > items"
      screenReaderPaginationLabel="Pagination"
      screenReaderPageLabel="page"
      screenReaderCurrentLabel="You're on page"
      (pageChange)="p = $event"
      previousLabel="上一頁"
      nextLabel="下一頁"
      class="col-md-3">
    </pagination-controls>

    <!-- 搜尋框 -->
    <div class="searchInput col-md-3 order-md-last order-first">
      <input
          type="text"
          placeholder="輸入暱稱以搜尋..."
          class="form-control rounded"
          [(ngModel)]="searchInput"
          (keyup)="onSearchChange()"
          (mouseup)="onSearchChange()">
        <span class="input-group-text border-0" id="search-addon" type="submit">
          <i *ngIf="!searchInput" class="fas fa-search"></i>
          <i *ngIf="searchInput" class="fas fa-times" (click)="clear()"></i>
        </span>
    </div>
  </section>
</ng-container>

<!-- 有資料、非載入中、無錯誤、Desktop table-->
<main *ngIf="agents.length && !isLoading && !error && curWidth > 576; else RWD" [@expandOnEnter]>

  <table class="table table-hover">
    <thead>
      <tr>
        <ng-container *ngIf="!selected || selected === 'default' || searchInput.length">
          <th>勾選</th>
        </ng-container>
        <ng-container *ngIf="(selected === 'email' || selected === 'coin') && !searchInput.length">
          <th>
            <span
              *ngIf="!none && !searchInput.length"
              (click)="checkAll()"
              class="pointer">
              <i class="far fa-check-square"></i>&nbsp;全選
            </span>
            <span
              *ngIf="none && !searchInput.length"
              (click)="cancelAll()"
              class="pointer underline">
            取消&nbsp;({{ agentId.length }})
            </span>
          </th>
        </ng-container>
        <ng-container *ngIf="!searchInput">
          <th>編號</th>
        </ng-container>
        <th scope="col">暱稱</th>
        <th scope="col">加入日期</th>
        <th scope="col">最後登入日期</th>
        <th scope="col">目標業績</th>
        <th scope="col">已達成業績</th>
        <th scope="col">退出前之業績</th>
        <th scope="col">已派發體驗金</th>
        <th scope="col">持有鑽幣</th>
        <th scope="col">達成率</th>
        <th scope="col">會員數</th>
      </tr>
    </thead>
    <tbody>
      <tr
      *ngFor="let agent of agents |
      filter:searchInput:'dNickname' |
      paginate: {
        itemsPerPage: items,
        currentPage: p
      };
      let i = index">
        <!-- 發信 -->
        <ng-container *ngIf="selected === 'email'">
          <td>
            <input
            *ngIf="!agent.joined && !agent.isGhost"
            [id]="i"
            type="checkbox"
            [value]="agent.userId"
            [checked]="agentId.includes(agent.userId)? true : false"
            (change)="toggleAgentId($event)">
          </td>
        </ng-container>
        <!-- 發錢 -->
        <ng-container  *ngIf="selected === 'coin'">
          <td>
            <input
            *ngIf="agent.joined && !agent.isGhost"
            [id]="i"
            type="checkbox"
            [value]="agent.userId"
            [checked]="agentId.includes(agent.userId)? true : false"
            (change)="toggleAgentId($event)">
          </td>
        </ng-container>
        <!-- nothing / default -->
        <ng-container *ngIf="(!selected || selected === 'default') && !searchInput.length">
          <td></td>
        </ng-container>
        <ng-container *ngIf="(!selected || selected === 'default') && searchInput.length">
          <td></td>
        </ng-container>
        <ng-container *ngIf="!searchInput">
          <td>
            <label [for]="i">
              {{ (p-1) * items + i < ghost ? '-' : ((p-1) * items  + (i-ghost+1)) }}
            </label>
          </td>
        </ng-container>
        <td>
          <label [for]="i">
            {{ agent.dNickname }}
          </label>
        </td>
        <td>
          <label [for]="i">
            {{ agent.joined | convertISO }}
          </label>
        </td>
        <td>
          <label [for]="i">
            {{ agent.lastLogin | convertISO }}
          </label>
        </td>
        <td>
          <label [for]="i">
            {{ agent.joined ? (agent.targetAchievement | number) : '-' }}
          </label>
        </td>
        <td>
          <label [for]="i">
            {{ agent.isGhost? '-' : (agent.currentAchievement | currency: '':'symbol':'1.0-0') }}
          </label>
        </td>
        <td>
          <label [for]="i">
            {{ agent.legacyAchievement | currency: '':'symbol':'1.0-0' }}
          </label>
        </td>
        <td>
          <label [for]="i">
            {{ agent.isGhost? '-' : (agent.sentTrialDCoin | number) }}
          </label>
        </td>
        <td>
          <label [for]="i">
            {{ agent.isGhost? '-' : (agent.currentDCoin | number) }}
          </label>
        </td>
          <td>
          <label [for]="i">
            {{ agent.joined ? ((agent.doneRate | toFixed) + '%') : '-' }}
          </label>
        </td>
        <td>
          <label [for]="i">
            {{ agent.joined ? (agent.memberCount | number) : '-' }}
          </label>
        </td>
      </tr>
    </tbody>
  </table>
</main>

<!-- 手機板 table -->
<ng-template #RWD>

  <main *ngIf="!error && ! isLoading && agents.length" [@expandOnEnter]>

    <ng-container *ngIf="(selected === 'email' || selected === 'coin') && !searchInput.length">
      <button
        *ngIf="!none && !searchInput.length"
        (click)="checkAll()"
        class="btn btn--all">
        <i class="far fa-check-square"></i>
        全選
      </button>
      <button
          *ngIf="none && !searchInput.length"
          (click)="cancelAll()"
          class="btn btn--all">
        取消&nbsp;({{ agentId.length }})
      </button>
    </ng-container>

    <!-- 佔位的 -->
    <ng-container *ngIf="!selected || selected === 'default'">
      <div class="take--over"></div>
    </ng-container>

    <pagination-controls
      *ngIf="agents.length > items"
      (pageChange)="p = $event"
      previousLabel="上一頁"
      nextLabel="下一頁"
    ></pagination-controls>

    <table
      class="table table-vertical table-striped"
      *ngFor="let agent of agents |
      filter:searchInput:'dNickname'|
      paginate: {
        itemsPerPage: items,
        currentPage: p
      }; let i = index">
    <tbody>
      <tr>
        <!-- 非搜尋模式：正常顯示編號 -->
        <ng-container *ngIf="!searchInput">
          <td>
            編號（{{ (p-1) * items + i < ghost ? '-' : ((p-1) * items  + (i-ghost+1)) }}）
          </td>
        </ng-container>
        <!-- 搜尋模式：!selected? 整列隱藏 : 空格佔位 -->
        <ng-container *ngIf="searchInput && (selected === 'email' || selected === 'coin')">
          <td>&nbsp;</td>
        </ng-container>
        <!-- 發信 -->
        <ng-container *ngIf="selected === 'email'">
          <td>
            <input
              *ngIf="!agent.joined && !agent.isGhost"
              [id]="i"
              type="checkbox"
              [value]="agent.userId"
              [checked]="agentId.includes(agent.userId)? true : false"
              (change)="toggleAgentId($event)">
          </td>
        </ng-container>
        <!-- 發錢 -->
        <ng-container *ngIf="selected === 'coin'">
          <td>
            <input
              *ngIf="agent.joined && !agent.isGhost"
              [id]="i"
              type="checkbox"
              [value]="agent.userId"
              [checked]="agentId.includes(agent.userId)? true : false"
              (change)="toggleAgentId($event)">
          </td>
        </ng-container>
          <!-- nothing / default -->
          <ng-container *ngIf="(!selected || selected === 'default') && !searchInput">
            <td></td>
          </ng-container>
        </tr>
        <tr>
          <td>暱稱</td>
          <td>{{ agent.dNickname }}</td>
        </tr>
        <tr>
          <td>加入日期</td>
          <td>{{ agent.joined | convertISO }}</td>
        </tr>
        <tr>
          <td>最後登入日期</td>
          <td>{{ agent.lastLogin | convertISO }}</td>
        </tr>
        <tr>
          <td>目標業績</td>
          <td>{{ agent.joined? (agent.targetAchievement | number) : '-' }}</td>
        </tr>
        <tr>
          <td>已達成業績</td>
          <td>{{ agent.isGhost ? '-' : (agent.currentAchievement | currency: '':'symbol':'1.0-0') }}</td>
        </tr>
        <tr>
          <td>退出前之業績</td>
          <td>{{ agent.legacyAchievement | currency: '':'symbol':'1.0-0' }}</td>
        </tr>
        <tr>
          <td>已派發體驗金</td>
          <td>{{ agent.isGhost ? '-' : (agent.sentTrialDCoin | number) }}</td>
        </tr>
        <tr>
          <td>持有鑽幣</td>
          <td>{{ agent.isGhost ? '-' : (agent.currentDCoin | number) }}</td>
        </tr>
        <tr>
          <td>達成率</td>
          <td>{{ agent.joined ? ((agent.doneRate | toFixed) + '%') : '-' }}</td>
        </tr>
        <tr>
          <td>會員數</td>
          <td>{{ agent.joined ? (agent.memberCount | number) : '-' }}</td>
        </tr>
      </tbody>
    </table>
  </main>
</ng-template>

<!-- 無資料狀態 -->
<app-no-info *ngIf="!agents.length && !isLoading && !error"></app-no-info>
