import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

// Environment
import { environment } from 'src/environments/environment';

// Model
import { Role } from "src/app/models/role.module";

// Service
import { EditPermissionService } from './edit-permission-service';
import { RoleService } from 'src/app/shared/services/role.service';
import { ReloadService } from 'src/app/shared/services/reload.service';


@Component({
  selector: 'app-edit-permission-modal',
  templateUrl: './edit-permission-modal.component.html',
  styleUrls: ['./edit-permission-modal.component.scss']
})

export class EditPermissionModalComponent implements OnInit {
  @ViewChild('f', { static: false })
  editPermissionForm!: NgForm;
  body = {
    accountInfo: '',
    businessManage: '',
    sellManage: '',
    financeManage: '0',
    agentManage: '',
    memberManage: '',
    systemManage: ''
  }

  // button 狀態
  state: string = '儲存';
  isStoring: boolean = false;

  role: Role[] = [];
  roleAuth: number = 0;
  auth: string[] = [];

  constructor(
    public modalRef: MdbModalRef<EditPermissionModalComponent>,
    private http: HttpClient,
    private roleService: RoleService,
    private reloadService: ReloadService,
    private EditPermissionService: EditPermissionService) { }

  async ngOnInit() {
    await this.http
      .get(`${environment.SellsServer}/api/System/Role/Authorizations`)
      .subscribe({
        // 成功: fetch
        next: (response: any) => {
          this.fetchData(response as Role[]);
        },
        // 完成:
        complete: () => {
          this.roleAuth = this.roleService.getRoleAuth();

          // 將後端回傳的十進制權限轉為二進制，並用 split 拆分
          this.auth = this.roleAuth.toString(2).padStart(7, '0').split('');

          // 一一賦值予 body（反向）
          this.body.accountInfo = this.auth[6];
          this.body.businessManage = this.auth[5];
          this.body.sellManage = this.auth[4];
          this.body.financeManage = this.auth[3];
          this.body.agentManage = this.auth[2];
          this.body.memberManage = this.auth[1];
          this.body.systemManage = this.auth[0];
        },
        // 失敗
        error: (err: any) => {
          console.log(err);
          this.modalRef.close();
        }
      })

  }

  fetchData(data: Role[]) {
    this.role = data;
  }

  onSubmit() {
    const value = this.editPermissionForm.value;
    // 將 value 組合為二進制表示
    const combine =
      value.systemManage +
      value.memberManage +
      value.agentManage +
      '0' +
      value.sellManage +
      value.businessManage +
      value.accountInfo;
    this.EditPermissionService.setPermission(combine);

    // console.log(combine);

    // 將二進制轉為十進制
    const auth = parseInt(combine, 2);
    const body = {
      role: this.roleService.getRole(),
      roleAuth: auth
    }

    // Showing spinner & State change
    this.isStoring = true;
    this.state = '儲存中';

    this.http
      .put(`${environment.SellsServer}/api/System/Role/Authorizations`, body)
      .subscribe({
        next: (response: any) => {
          // 成功
          setTimeout(() => {
            this.isStoring = false;
            this.state = '儲存成功';

            setTimeout(() => {
              // 1.5 秒後關閉 modal

              this.modalRef.close();
              this.reloadService.redirectTo('system-manage/backstage-manage')
            }, 1500)

          }, 1500)
        },
        error: (err: any) => {
          // 失敗
          setTimeout(() => {
            this.isStoring = false;
            this.state = '儲存失敗';

            setTimeout(() => {
              this.modalRef.close();
              console.log(err);
            }, 1500)

          }, 2000)
        }
      })
  }
}
