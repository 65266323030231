import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

// Enviroment
import { environment } from 'src/environments/environment';

// Service
import { ReloadService } from 'src/app/shared/services/reload.service';

@Component({
  selector: 'app-new-channel-modal',
  templateUrl: './new-channel-modal.component.html',
  styleUrls: ['./new-channel-modal.component.scss']
})
export class NewChannelModalComponent implements OnInit {
  @ViewChild('f', { static: false })
  newForm!: NgForm;
  // button 狀態管理
  state: string = '確認';
  isStoring: boolean = false;
  newChannel = '';

  constructor(
    public modalRef: MdbModalRef<NewChannelModalComponent>,
    private http: HttpClient,
    private reloadService: ReloadService
  ) { }

  ngOnInit(): void {
  }

  onSubmit() {
    // Showing spinner & State change
    this.isStoring = true;
    this.state = '儲存中';

    this.http
      .post(`${environment.SellsServer}/api/Channels`, `"${this.newForm.value.name}"`)
      .subscribe({
        next: (response: any) => {
          setTimeout(() => {
            this.isStoring = false;
            this.state = '新增成功';

            setTimeout(() => {
              this.modalRef.close();
              this.reloadService.redirectTo('sell-manage/sell-setting');
            }, 1500)

          }, 1500)
        },
        error: (err: any) => {
          setTimeout(() => {
            this.isStoring = false;
            this.state = '新增失敗';
            console.log(err);

            setTimeout(() => {
              this.newChannel = '';
              this.state = '確認';
            }, 2000)

          }, 1500)
        }
      })
  }
}
