// 套件
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// component
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AccountComponent } from './main/account/account.component';
import { AgentManageComponent } from './main/agent-manage/agent-manage.component';
import { BusinessManageComponent } from './main/business-manage/business-manage.component';
import { FinanceReportComponent } from './main/finance-report/finance-report.component';
import { MemberManageComponent } from './main/member-manage/member-manage.component';
import { SellManageComponent } from './main/sell-manage/sell-manage.component';
import { SystemManageComponent } from './main/system-manage/system-manage.component';
import { OperationDetailComponent } from './main/business-manage/operation-detail/operation-detail.component';
import { FinanceDetailComponent } from './main/business-manage/finance-detail/finance-detail.component';
import { GaSettingComponent } from './main/business-manage/ga-setting/ga-setting.component';
import { PermissionSettingComponent } from './main/business-manage/permission-setting/permission-setting.component';
import { BackstageManageComponent } from './main/system-manage/backstage-manage/backstage-manage.component';
import { BackstageRecordComponent } from './main/system-manage/backstage-record/backstage-record.component';
import { SellResultComponent } from './main/sell-manage/sell-result/sell-result.component';
import { SellSettingComponent } from './main/sell-manage/sell-setting/sell-setting.component';

// 驗證權限
import { AuthenticationGuard } from './shared/services/authentication-guard.guard';


// 路由
const routes: Routes = [
  { path: '', redirectTo: 'dashboard/account', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: 'account',
        data: { title: '帳號資訊' },
        component: AccountComponent,
      },
      {
        path: 'business-manage',
        component: BusinessManageComponent,
        children: [
          {
            path: 'operation-detail',
            data: { title: '營運操作__營運數據' },
            component: OperationDetailComponent
          },
          {
            path: 'finance-detail',
            data: { title: '營運操作__帳務資訊' },
            component: FinanceDetailComponent
          },
          {
            path: 'ga-setting',
            data: { title: '營運操作__總代設置' },
            component: GaSettingComponent
          },
          {
            path: 'permission-setting',
            data: { title: '營運操作__權限設置' },
            component: PermissionSettingComponent
          },
        ]
      },
      {
        path: 'sell-manage',
        data: { title: '推廣控管' },
        component: SellManageComponent,
        children: [
          { path: '', redirectTo: 'sell-result', pathMatch: 'full' },
          { path: 'sell-result', component: SellResultComponent },
          { path: 'sell-setting', component: SellSettingComponent }
        ]
      },
      // {
      //   path: 'finance-report',
      //   data: { title: '帳務報表' },
      //   component: FinanceReportComponent
      // },
      {
        path: 'agent-manage',
        data: { title: '代理控管' },
        component: AgentManageComponent
      },
      {
        path: 'member-manage',
        data: { title: '會員控管' },
        component: MemberManageComponent
      },
      {
        path: 'system-manage',
        component: SystemManageComponent,
        children: [
          // {
          //   path: 'backstage-record',
          //   data: { title: '系統管理__後台操作紀錄' },
          //   component: BackstageRecordComponent
          // },
          {
            path: 'backstage-manage',
            data: { title: '系統管理__後台操作管理' },
            component: BackstageManageComponent,
            canMatch: [AuthenticationGuard],
          }
        ]
      },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }),
    BrowserModule,
    BrowserAnimationsModule,
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
