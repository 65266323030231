<div class="wrap">

  <div class="modal-header">
    <h2 id="exampleModalLabel" class="modal-title">
      體驗金／鑽幣分派</h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
  </div>

  <div class="modal-body">

    <form
    [formGroup]="mdcoinForm"
    (ngSubmit)="onSubmit()"
    class="form">

    <div class="form__radio--group">
      <div>

        <input
        formControlName="kind"
        type="radio"
        value="1"
        id="money">
        <label for="money" class="form__label">體驗金</label>
      </div>

      <div>
        <input
        formControlName="kind"
        type="radio"
        value="2"
        id="coin">
        <label for="coin" class="form__label">鑽幣</label>
      </div>
    </div>

      <div class="form__input--group">
        <label for="amount" class="form__label">金額輸入</label>
        <div>
          <input
          formControlName="amount"
          class="form-control"
          type="number"
          id="amount"
          min="0"
          class="form__input">
        </div>
      </div>

      <div *ngIf="!error" class="text-center d-flex justify-content-center align-items-center">
        <input
        formControlName="check"
        id="check"
        type="checkbox">
        <label for="check" class="form__label fs-4 ms-2">
          金額確認
        </label>
      </div>

      <p *ngIf="error" class="text-center fs-4 text-danger">{{ errorMessage }}</p>

      <button
      class="btn btn--request"
      type="submit"
      [disabled]="error || !mdcoinForm.valid || state !== '確認'"
      (click)="onSubmit()">
      <span
      *ngIf="isStoring"
      class="spinner-border spinner-border-sm"
        ></span>
        {{ state }}
      </button>

    </form>

  </div>

</div>
