import { Injectable } from "@angular/core";
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })

export class ReloadService {
  constructor(private router: Router) {}

  async redirectTo(url: string) {
    await this.router.navigateByUrl('/', { skipLocationChange: true });
    this.router.navigate([`dashboard/${url}`]);
  }

}
