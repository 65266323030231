import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Environment
import { environment } from 'src/environments/environment';

// Module
import { Agent } from 'src/app/models/agent';

// Service
import { GetTimeService } from 'src/app/shared/services/getTime.service';

@Injectable({
  providedIn: 'root'
})
export class AgentManageService {

  constructor(
    private http: HttpClient,
    private getTimeService: GetTimeService
  ) { }

  getAgents(start: string, end: string) {
    const startISO = this.getTimeService.transferStart(start);
    const endISO = this.getTimeService.transferEnd(end);

    return this.http
      .get<Agent[]>(`${environment.SellsServer}/api/Agents/from/${startISO}/to/${endISO}`)
      .pipe(
        catchError(this.handleError)
      )
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('An error from server.'));
  }
}
