import { Component, HostListener, OnInit } from '@angular/core';

// Modal
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { SellResultModalComponent } from './sell-result-modal/sell-result-modal.component';

// Module
import { ChannelResult } from 'src/app/models/channel.module';

// Service

import { ParamsService } from '../params.service';
import { SellResultService } from './sell-result.service';

// Animation
import { expandOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-sell-result',
  templateUrl: './sell-result.component.html',
  styleUrls: ['./sell-result.component.scss'],
  animations: [
    expandOnEnterAnimation()
  ]
})
export class SellResultComponent implements OnInit {
  modalRef: MdbModalRef<SellResultModalComponent> | null = null;
  isLoading: boolean = false; // 載入狀態
  error: boolean = false; // 錯誤
  curWidth: number = 0; // 視窗寬度
  channels: ChannelResult[] = []; // 推廣成果
  tipContent: string = '查詢選擇日期內的推廣連結分享成果，預設顯示七日內資訊。';

  constructor(
    private modalService: MdbModalService,
    private paramsService: ParamsService,
    private sellResultService: SellResultService) {
  }

  async ngOnInit(): Promise<void> {
    // 依據 width 決定渲染電腦版 or 手機板
    this.curWidth = window.innerWidth;
    this.getChannel('past');
  }

  // 監聽 window 寬度
  @HostListener('window:resize', ['$event']) onResize() {
    this.curWidth = window.innerWidth;
  }

  openModal(url: string) {
    this.modalRef = this.modalService.open(SellResultModalComponent, {
      modalClass: 'modal-dialog-centered'
    });

    this.paramsService.saveParams(url);
  }

  /**
   *
   * @param 取得推廣管道列表
   */
  getChannel(date: any) {

    // 載入中
    this.isLoading = true;

    // 依此 function 的參數送出不同的參數
    // 參數為 'past' 則不送參數（只送 empty string）
    // 在 Service 的 function 會依據有無參數指定不同的日期區間
    this.sellResultService
      .getChannel(date === 'past' ? '' : (date.start, date.end))
      .subscribe({
        next: (response: any) => {
          setTimeout(() => {
            // 成功，停止載入並刷新資料
            this.isLoading = false;
            this.channels = response;
          }, 500)
        },
        error: (err: any) => {
          setTimeout(() => {
            this.isLoading = false;
            this.error = true;
            console.log(err);
          }, 2000)
        }
      })
  }
}
