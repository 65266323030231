<div class="wrap">

  <div class="modal-header">

    <h2 id="exampleModalLabel" class="modal-title">
      身份調整</h2>

    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>

  </div>

  <div class="modal-body">
    <h4 class="mb-3 text-center">*目前僅開放「代理升為總代」與「代理降為會員」</h4>

    <form (ngSubmit)="onSubmit()" #f="ngForm" class="form">

        <div>
          <input
          [(ngModel)]="role"

          type="radio"
          value="4"
          name="role"
          class="form__input"
          id="ga">
          <label for="ga">總代</label>
        </div>

        <div>
          <input
          [(ngModel)]="role"

          type="radio"
          value="2"
          name="role"
          class="form__input"
          id="member">
          <label for="member">會員</label>
        </div>

        <button
        class="btn btn--request"
        type="submit"
        [disabled]="state !== '儲存' || this.f.invalid">
        <span
        *ngIf="isStoring"
        class="spinner-border spinner-border-sm">
        </span>
          {{ state }}
        </button>

    </form>

  </div>

</div>
