import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'filter'
})

export class FilterPipe implements PipeTransform {

  transform(value: any, filterString: string, propName: string):any {
    if (value.length === 0 || filterString === '') {
      return value
    }

    const result = [];

    // item: 陣列中的物件
    for (let item of value) {
      if (
        item[propName]
          .toLowerCase()
          .includes(
            filterString
            .trim()
            .toLowerCase()
            )) {
        result.push(item);
      }
    }

    return result
  }
}
