<!-- 載入中 -->
<app-loading-spinner *ngIf="isLoading && !error"></app-loading-spinner>

<!-- 錯誤 -->
<app-error-alert *ngIf="error && !isLoading"></app-error-alert>


<main *ngIf="!error && ! isLoading && operations.length" [@expandOnEnter]>

  <div class="tab searchInput">
    <div class="input-group rounded tab__search">
      <input
        type="text"
        placeholder="輸入暱稱以搜尋..."
        class="form-control rounded"
        [(ngModel)]="searchInput"
        (keyup)="onSearchChange()"
        (mouseup)="onSearchChange()">
      <span class="input-group-text border-0" id="search-addon" type="submit">
        <i *ngIf="!searchInput" class="fas fa-search"></i>
        <i *ngIf="searchInput" class="fas fa-times" (click)="clear()"></i>
      </span>
    </div>

    <button
      class="btn btn--request tab__button"
      (click)="openPermissionModal()"
      [disabled]="!operationId.length">
        權限變更
    </button>

    <button
      class="btn btn--request tab__button"
      (click)="openIndentityModal()"
      [disabled]="!operationId.length">
        身份調整
    </button>

    <!-- RWD 全選／取消 -->
    <ng-container *ngIf="!searchInput">
      <button
        *ngIf="!none && curWidth < 576"
        class="btn btn--all"
        (click)="checkAll()">
        <i class="far fa-check-square"></i>
        全選
      </button>
      <button
        *ngIf="none && curWidth < 576"
        class="btn btn--all"
        (click)="cancelAll()">
        取消&nbsp;({{ operationId.length }})
      </button>
    </ng-container>
  </div>

  <pagination-controls
    *ngIf="operations.length > items"
    (pageChange)="p = $event"
    previousLabel="上一頁"
    nextLabel="下一頁">
  </pagination-controls>

  <!-- 有資料、非載入中、無錯誤、Desktop table -->
  <table class="table table-hover" *ngIf="curWidth > 576; else RWD">
    <thead>
      <tr>
        <th scope="col" *ngIf="searchInput.length">勾選</th>
        <th scope="col" *ngIf="!none && !searchInput.length" (click)="checkAll()" class="pointer">
          <i class="far fa-check-square"></i>
          全選
        </th>
        <th scope="col" *ngIf="none && !searchInput.length" (click)="cancelAll()" class="pointer underline">取消&nbsp;({{ operationId.length }})</th>
        <th scope="col">編號</th>
        <th scope="col">暱稱</th>
        <th scope="col">身份</th>
        <th scope="col">上級帳號</th>
        <th scope="col">上級暱稱</th>
        <th scope="col">後台登入</th>
        <th scope="col">後台操作</th>
        <th scope="col">推廣設置</th>
      <th scope="col">發送認證信</th>
      <th scope="col">派發體驗金／鑽幣</th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let o of operations |
      filter:searchInput:'dNickname' |
      paginate: {
        itemsPerPage: items,
        currentPage: p
      };
      let i = index">
    <td>
      <input
        [id]="i"
        type="checkbox"
        [checked]="operationId.includes(o.userId)? true : false"
        [value]="o.userId"
        (change)="toggleOperationId($event)">
    </td>
    <td>
      <label [for]="i">
        {{ (p-1) * items  + (i+1) }}
      </label>
    </td>
    <td>
      <label [for]="i">
        {{ o.dNickname }}
      </label>
        </td>
        <td>
          <label [for]="i">
            {{ o.role }}
          </label>
        </td>
        <td>
          <label [for]="i">
            {{ o.parentAccount? o.parentAccount : '無' }}
          </label>
        </td>
        <td>
          <label [for]="i">
            {{ o.parentDNickname? o.parentDNickname : '無' }}
          </label>
        </td>
        <td>
          <label [for]="i">
            {{ o.auth | bitWise:1  }}
          </label>
        </td>
        <td>
          <label [for]="i">
            {{ o.auth | bitWise:2  }}
          </label>
        </td>
        <td>
          <label [for]="i">
            {{ o.auth | bitWise:4  }}
          </label>
        </td>
        <td>
          <label [for]="i">
            {{ o.auth | bitWise:8  }}
          </label>
        </td>
        <td>
          <label [for]="i">
            {{ o.auth | bitWise:16  }}
          </label>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- 手機板 table -->
  <ng-template #RWD>
    <table
      class="table table-vertical table-striped"
      *ngFor="let o of operations |
      filter:searchInput:'dNickname' |
      paginate: {
        itemsPerPage: items,
        currentPage: p
      };
      let i = index">
      <tbody>
        <tr>
          <td>編號 ({{ (p-1) * items  + (i+1) }})</td>
          <td>
            <input
              [id]="i"
              type="checkbox"
              [checked]="operationId.includes(o.userId)? true : false"
              [value]="o.userId"
              (change)="toggleOperationId($event)">
          </td>
        </tr>
        <tr>
          <td>暱稱</td>
          <td>{{ o.dNickname }}</td>
        </tr>
        <tr>
          <td>身份</td>
          <td>{{ o.role }}</td>
        </tr>
        <tr>
          <td>上級帳號</td>
          <td>{{ o.parentAccount? o.parentAccount : '無' }}</td>
        </tr>
        <tr>
          <td>上級暱稱</td>
          <td>{{ o.parentDNickname? o.parentDNickname : '無' }}</td>
        </tr>
        <tr>
          <td>後台登入</td>
          <td>{{ o.auth | bitWise:1 }}</td>
        </tr>
        <tr>
          <td>後台操作</td>
          <td>{{ o.auth | bitWise:2 }}</td>
        </tr>
        <tr>
          <td>推廣設置</td>
          <td>{{ o.auth | bitWise:4 }}</td>
        </tr>
        <tr>
          <td>發送認證信</td>
          <td>{{ o.auth | bitWise:8 }}</td>
        </tr>
        <tr>
          <td>派發體驗金／鑽幣</td>
          <td>{{ o.auth | bitWise:16 }}</td>
        </tr>
      </tbody>
    </table>
  </ng-template>

</main>

  <!-- 無資料狀態 -->
  <app-no-info *ngIf="!operations.length && !isLoading && !error"></app-no-info>
