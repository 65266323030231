<!-- Header -->
<app-header (drawerControl)="drawerControl($event)"></app-header>

<!-- 包含側欄的主要區域 -->
<mat-drawer-container autosize>

  <!-- 側欄（抽屜） -->
  <mat-drawer #drawer mode="side" [(opened)]="isOpen">

    <app-nav [permissionData]="permissionData" (drawerControl)="drawerControl($event)"></app-nav>

  </mat-drawer>


  <!-- 側欄箭頭 -->
  <div *ngIf="curWidth >= 768" class="sidenav-toggle">
    <img
    src="assets/image/Diamondonline_personalbusiness_btn_draw_65x148.png"
    class="drawer--button"
    alt="drawer"
    type="button"
    (click)="drawer.toggle()">
  </div>

  <!-- 分頁內容 -->
  <main class="main">

    <!-- 分頁標題 -->
    <div class="page">
      <span class="page__decoration"></span>
      <div class="page__text">
        <p class="page__title">{{ pageTitle | splitTitle:0 }}</p>
        <span class="page__sub">{{ pageTitle | splitTitle:1 }}</span>
      </div>
    </div>

    <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
    <ng-container  *ngIf="!isLoading">
      <app-main *ngIf="isPermit; else forbidden"></app-main>
      <ng-template  #forbidden>
        <h2 class="bg-danger bg-gradient text-light p-2 text-center">
          你沒有權限。
        </h2>
      </ng-template>
    </ng-container>

    <!-- 佔位的 -->
    <div class="block"></div>

  </main>

</mat-drawer-container>

<app-footer *ngIf="curWidth >= 768"></app-footer>
