import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';

import { routerPermission } from '../../../app/models/role-permission.model'

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  /**
   * 系統平台登入驗證
   * @param {string} username 使用者輸入帳號
   * @param {string}  password 使用者輸入密碼
   * @returns 登入成功後存入token
   */
  async login(username: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.SellsServer}/api/Account/login`, {
        username: username,
        password: password
      }).subscribe({
        next: (response: any) => {
          // 使用者登入成功，將登入成功取得的 token 存入 browser local storage 中
          localStorage.setItem('token', response.token);
          resolve(response);
        },
        error: (error: any) => {
          // 使用者登入失敗
          console.log(error.response);
          reject(error);
        }
      });
    });
  }

  /**
   * 登出：移除 localStorage 的 token
   */
  logout(): void {
    window.location.replace('');
    localStorage.removeItem("token");
  }

  /**
   * 紀錄是否為登入狀態
   * @returns {boolean}
   */
  isUserLoggedIn(): boolean {
    if (localStorage.getItem("token") != null) {
      return true;
    }
    return false;
  }

  /**
   * 取得所有路由造訪權限
   */
  rolePermission() {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.SellsServer}/api/System/Role/GetCurrentRoleAuth`).subscribe({
        next: (response: any) => {
          let roleAuth = response.toString(2); // 十進制轉二進制
          roleAuth = roleAuth.padStart(7, '0'); // 最前面為0的時候不會顯示該位數，故要補0

          let permissionData: routerPermission =
          {
            "account": true, // 帳號資訊
            "business-manage": Boolean(Number((roleAuth[5]))), // 營運操作
            "sell-manage": Boolean(Number((roleAuth[4]))), // 推廣控管
            "finance-report": Boolean(Number((roleAuth[3]))), // 帳務報表
            "agent-manage": Boolean(Number((roleAuth[2]))), // 代理控管
            "member-manage": Boolean(Number((roleAuth[1]))), // 會員控管
            "system-manage": Boolean(Number((roleAuth[0]))), // 系統管理
          };

          resolve(permissionData);
        },
        error: (error: any) => {
          console.log("授權失敗：" + error);
          reject(error);
        }
      });
    });
  }
}
