import { Component, OnInit } from '@angular/core';

// Environment
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-finance-detail',
  templateUrl: './finance-detail.component.html',
  styleUrls: ['./finance-detail.component.scss']
})
export class FinanceDetailComponent implements OnInit {
  url: string = `${environment.FinanceDetail}`

  constructor() { }

  ngOnInit(): void {}

}
