<!-- 此帳號資訊 -->
<section class="col-md-4">
  <table class="table">
    <thead>
      <tr>
        <th scope="col">體驗金額度</th>
        <th scope="col">持有鑽幣</th>
        <th scope="col">個人儲值金額</th>
      </tr>
    </thead>

    <tbody>
      <tr>
        <td>
          <span
          *ngIf="isGettingAuth"
          class="spinner-border spinner-border-sm">
          </span>
          <span *ngIf="!isGettingAuth && !error" [@fadeInOnEnter]>
            {{ myAgent.trialDCoin | number }}
          </span>
          <span *ngIf="error" class="text-danger">{{ errorMessage }}</span>
        </td>
        <td>
          <span
          *ngIf="isGettingAuth"
          class="spinner-border spinner-border-sm">
          </span>
          <span *ngIf="!isGettingAuth && !error" [@fadeInOnEnter]>
            {{ myAgent.dCoin | number }}
          </span>
          <span *ngIf="error" class="text-danger">{{ errorMessage }}</span>
        </td>
        <td>
          <span
          *ngIf="isGettingAuth"
          class="spinner-border spinner-border-sm">
          </span>
          <span *ngIf="!isGettingAuth && !error" [@fadeInOnEnter]>
            {{ myAgent.storedMoney | currency: '':'symbol':'1.0-0' }}
          </span>
          <span *ngIf="error" class="text-danger">{{ errorMessage }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</section>
