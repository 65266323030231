<main class="wrap">

  <header class="modal-header">
    <h2 id="exampleModalLabel" class="modal-title">
      薪資試算
      <i class="fas fa-calculator"></i>
    </h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
  </header>

  <section class="modal-body">

    <div class="row">
      <ul class="col-md-6 col-7 fs-3 text-center">
        <li *ngIf="role === 4">
          <span>
            【管理獎金】
          </span>
          <span *ngIf="isLoading && !error" class="spinner-border spinner-border-sm"></span>
          <span *ngIf="error" class="text-danger">{{ errorMessage }}</span>
          <span *ngIf="!isLoading && !error" [@fadeInUpOnEnter]>
            {{ salary.gaSalary | currency:'':'symbol':'0.0-0' }}
          </span>
        </li>
        <hr *ngIf="role === 4" class="hr" />
        <li>
          <span>
            【儲值獎金】
          </span>
          <span *ngIf="isLoading && !error" class="spinner-border spinner-border-sm"></span>
          <span *ngIf="error" class="text-danger">{{ errorMessage }}</span>
          <span *ngIf="!isLoading && !error" [@fadeInUpOnEnter]>
            {{ salary.storedSalary | currency:'':'symbol':'0.0-0' }}
          </span>
        </li>
        <hr class="hr" />
        <li>
          <span>
            【玩家遊玩獎金】
          </span>
          <span *ngIf="isLoading && !error" class="spinner-border spinner-border-sm"></span>
          <span *ngIf="error" class="text-danger">{{ errorMessage }}</span>

          <span *ngIf="!isLoading && !error" [@fadeInUpOnEnter]>
            {{ salary.betSalary | currency:'':'symbol':'0.0-0' }}
          </span>
        </li>
        <hr class="hr" />
        <li>
          <span>
            【個人達標獎金】
          </span>
          <span *ngIf="isLoading && !error" class="spinner-border spinner-border-sm"></span>
          <span *ngIf="error" class="text-danger">{{ errorMessage }}</span>

          <span *ngIf="!isLoading && !error" [@fadeInUpOnEnter]>
            {{ salary.targetSalary | currency:'':'symbol':'0.0-0' }}
          </span>
        </li>
      </ul>

      <div class="col-md-6 col-5 text-center d-flex justify-content-center flex-column mb-5">
        <p class="fs-3">
          【總計】<br>
          <span *ngIf="isLoading && !error" class="spinner-border spinner-border-sm"></span>
          <span *ngIf="error" class="text-danger">{{ errorMessage }}</span>

          <span *ngIf="!isLoading && !error" [@zoomInOnEnter] class="fs-1 fw-bold badge bg-warning p-3">
            {{ total | currency:'':'symbol':'0.0-0' }}
          </span>
        </p>
      </div>

    </div>

  </section>

</main>
