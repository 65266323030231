<div class="wrap">

  <div class="modal-header">
    <h2 id="exampleModalLabel" class="modal-title">
      分成比／業績設定</h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
  </div>

  <div class="modal-body">

    <form
    [formGroup]="achievementForm"
    (ngSubmit)="onSubmit()"
    class="form">

      <div class="form__input--group">
        <label for="percentOfAchievement" class="form__label">業績分成</label>
        <div>
          <input
          formControlName="percentOfAchievement"

          placeholder="可輸入至小數點後第一位"
          type="number"
          id="percentOfAchievement"
          class="form__input form__placeholder"
          min="0">
          <span class="form__unit">％</span>
        </div>
      </div>

      <div class="form__input--group">
        <label for="target" class="form__label">業績目標</label>
        <div>
          <input
          formControlName="targetAchievement"

          type="number"
          id="target"
          class="form__input"
          min="0">
          <span class="form__unit">元</span>
        </div>
      </div>

      <button
      type="submit"
      class="btn btn--request"
      [disabled]="!achievementForm.valid">儲存</button>

    </form>

  </div>

</div>
