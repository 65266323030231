import { Component, OnInit } from '@angular/core';

// Animation
import { fadeInOnEnterAnimation } from 'angular-animations';

// Service
import { MyAgentService } from './myAgent.service';

// Model
import { MyAgentInfo } from "src/app/models/myAgentInfo";


@Component({
  selector: 'app-my-agent',
  templateUrl: './my-agent.component.html',
  styleUrls: ['./my-agent.component.scss'],
  animations: [
    fadeInOnEnterAnimation()
  ]
})
export class MyAgentComponent implements OnInit {
  isGettingAuth: boolean = false; // Getting MyAnent
  error: boolean = false;
  errorMessage: string = '---錯誤---';

  // MyAgent Info
  myAgent: MyAgentInfo = {
    dNickname: '',
    dAccount: '',
    dCoin: 0,
    trialDCoin: 0,
    storedMoney: 0
  }

  constructor(private myAgentService: MyAgentService) { }

  ngOnInit(): void {
    this.getMyAgent();
  }

  getMyAgent() {
    this.isGettingAuth = true;

    this.myAgentService.getMyAgent().subscribe({
      next: (response: any) => {
        setTimeout(() => {
          this.isGettingAuth = false;
          this.myAgent = response;
          this.myAgentService.setdCoin(this.myAgent.dCoin);
          this.myAgentService.setTrialCoin(this.myAgent.trialDCoin);
        }, 500)
      },
      error: (err: any) => {
        setTimeout(() => {
          this.isGettingAuth = false;
          this.error = true;
          console.log(err);
        }, 500);
      }
    })
  }
}
