import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

// Animation
import { zoomInOnEnterAnimation, fadeInUpOnEnterAnimation } from 'angular-animations';

// Service
import { AccountService } from '../account.service';

// Model
import { Salary } from 'src/app/models/salary';

@Component({
  selector: 'app-salary-modal',
  templateUrl: './salary-modal.component.html',
  styleUrls: ['./salary-modal.component.scss'],
  animations: [
    fadeInUpOnEnterAnimation(),
    zoomInOnEnterAnimation(),
  ]
})
export class SalaryModalComponent implements OnInit {
  isLoading: boolean = true;
  total: number = 0; // 總額
  role: number = 3; // 角色身份，預設代理
  error: boolean = false; // 錯誤
  errorMessage: string = '系統異常'; // 錯誤訊息

  salary: Salary = {
    gaSalary: 0,
    storedSalary: 0,
    betSalary: 0,
    targetSalary: 0
  };

  constructor(
    public modalRef: MdbModalRef<SalaryModalComponent>,
    private accountService: AccountService
  ) { }

  async ngOnInit(): Promise<void> {
    this.role = this.accountService.getRole();
    this.getSalary();
  }

  /**
   * 取得薪水資訊
   */
  getSalary() {
    this.accountService.onGetSalary()
      .subscribe({
        next: async (response: any) => {
          this.salary = response;
          this.total = Object.values(this.salary).reduce((c, u) => c + u);
          this.isLoading = false;
        },
        error: (err: any) => {
          this.isLoading = false;
          this.error = true;
          console.log(err);
        }
      })
  }
}
