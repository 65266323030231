import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { CheckboxService } from 'src/app/shared/services/checkbox.service';
import { ReloadService } from 'src/app/shared/services/reload.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mail-modal',
  templateUrl: './mail-modal.component.html',
  styleUrls: ['./mail-modal.component.scss']
})
export class MailModalComponent implements OnInit {
  // button 狀態管理
  state: string = '寄送';
  isStoring: boolean = false;


  constructor(
    public modalRef: MdbModalRef<MailModalComponent>,
    private http: HttpClient,
    private checkboxService: CheckboxService,
    private reloadService: ReloadService) { }

  ngOnInit(): void {
  }

  onSubmit() {
    const body = this.checkboxService.getAgentIdList();


    // Showing spinner & State change
    this.isStoring = true;
    this.state = '發送中';

    this.http
      .post(
        `${environment.SellsServer}/api/Agents/SendInvitationMail`, body)
      .subscribe({
        next: (response: any) => {
          // 成功
          setTimeout(() => {
            this.isStoring = false;
            this.state = '發送成功'

            setTimeout(() => {
              // 1.5 秒後關閉 modal
              this.modalRef.close();
              this.reloadService.redirectTo('agent-manage')
            }, 1500)

          }, 1500)
        },
        error: (err: any) => {
          // 失敗
          setTimeout(() => {
            this.isStoring = false;
            this.state = '發送失敗';

            setTimeout(() => {
              this.state = '寄送';
            }, 2000)

          }, 1500)
        }
      })
  }

}
