import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })

export class ParamsService {
  // 傳遞 url 供 qrcode 使用的 service
  params: string = '';

  /**
   * 將 Params 存入 Service
   */
  saveParams(params: any): void {
    this.params = params;
  }

  /**
   * 拿取 Params
   * @returns
   */
  getParams() {
    return this.params
  }
}
