import { Injectable } from "@angular/core";
import { Agent } from "src/app/models/agent";
import { Member } from "src/app/models/member";
import { Ga } from "src/app/models/ga";
import { Operations } from "src/app/models/operations.model";

@Injectable({ providedIn: 'root' })

export class CheckboxService {
  gaIdList: number[] = [];
  agentIdList: number[] = [];
  memberIdList: number[] = [];

  /**
   * 檢查 array 中有無此 value
   * */
  isChecked(array: number[], value: number) {
    if (!array.includes(value)) {
      // value not exist: push
      return array.push(value);
    } else {
      // value exist: delete
      return array.splice(array.indexOf(value), 1);
    }
  }

  /**
   *  Member 全選
   */
  allMembers(member: Member[], array: number[], str: string, page: number, items: number) {
    // console.log(page);
    // console.log(member.slice((page-1) * 10, 10));

    // 讓 arr 只包含當頁 10 筆資料
    // 新增幽靈人口後就不適用了 QQ
    // let arr = member.filter((value) => {
    //   if (
    //     member.indexOf(value) < page * 10
    //     && member.indexOf(value) >= (page-1) * 10) {
    //     return value
    //   } else {
    //     return
    //   }
    // })
    // console.log(arr);

    // arr.forEach((item) => {
    //   if (
    //     // 全選 發信
    //     str === 'email'
    //     && !item.joined
    //     && !array.includes(item.dUid)
    //   ) {
    //     array.push(item.dUid);
    //   } else if (
    //     // 全選 發錢
    //     str === 'coin'
    //     && item.joined
    //     && !array.includes(item.dUid)
    //   ) {
    //     array.push(item.dUid);
    //   }
    // })

      member.forEach((item) => {
        if (
          // 全選 發信
          str === 'email'
          && !item.joined
          && !item.isGhost
          && !array.includes(item.dUid)
          ) {
            array.push(item.dUid);
          } else if (
            // 全選 發錢
            str === 'coin'
            && item.joined
            && !item.isGhost
            && !array.includes(item.dUid)
            ) {
              array.push(item.dUid);
            }
          })
        }

  /**
   *  Agents 全選
   */
  allAgents(agent: Agent[], array: number[], str: string, items: number) {
    agent.forEach((item) => {
      if (
        // 全選 發信
        str === 'email'
        && !item.joined
        && !item.isGhost
        && !array.includes(item.userId)
      ) {
        array.push(item.userId);
      } else if (
        // 全選 發錢
        str === 'coin'
        && item.joined
        && !item.isGhost
        && !array.includes(item.userId)
      ) {
        array.push(item.userId);
      }
    })
  }

  /**
   *  Gas 全選
   */
  allGas(ga: Ga[], array: number[], items: number) {
    ga.forEach((item) => {
      if (!array.includes(item.userId)) {
        array.push(item.userId);
      }
    })
  }

  /**
   *  Operations 全選
   */
  allOperations(operation: Operations[], array: number[], items: number) {
    operation.forEach((item) => {
      if (!array.includes(item.userId)) {
        array.push(item.userId);
      }
    })
  }

  /**
   * 將 Ga ID 存至 Service 以共享
   * @param id
   */
  sendGaIdList(id: number[]) {
    this.gaIdList = id;
  }

  /**
   * 取得 Ga ID
   * @returns
   */
  getGaIdList() {
    return this.gaIdList
  }

  /**
   * 將 Agent ID 存至 Service 以共享
   * @param id
   */
  sendAgentIdList(id: number[]) {
    this.agentIdList = id;
  }

  /**
   * 取得 Agent ID
   * @returns
   */
  getAgentIdList() {
    return this.agentIdList
  }

  /**
   * 將 Member ID 存至 Service 以共享
   * @param id
   */
  sendMemberIdList(id: number[]) {
    this.memberIdList = id;
  }

  /**
   * 取得 Member ID
   * @returns
   */
  getMemberIdList() {
    return this.memberIdList
  }
}
