<div class="wrap">

  <div class="modal-header">

    <h4 id="exampleModalLabel" class="modal-title">
      密碼限 4~16 個字元；
      建議英數字混用，不可包含特殊符號。</h4>
      <button type="button" class="btn-close" (click)="modalRef.close()"></button>
    </div>

    <div class="modal-body">
      <p
      class="form__warning"
      *ngIf="errorMessage; else okay">
        {{ errorMessage }}
      </p>

      <ng-template #okay>
        <p>&nbsp;</p>
      </ng-template>

      <form
      [formGroup]="passwordForm"
      (ngSubmit)="onSubmit()"
      ngNativeValidate>

      <input
      formControlName="oldPassword"
      (focus)="onFocus()"

      type="password"
      id="oldPassword"
      class="form-control"
      placeholder="舊密碼"
      required
      minlength="4"
      maxlength="16"/>

      <input
      formControlName="newPassword"
      (focus)="onFocus()" 

      type="password"
      id="newPassword"
      class="form-control"
      placeholder="新密碼"
      required
      minlength="4"
      maxlength="16"/>

      <input
      formControlName="newPasswordDoubleCheck"
      (focus)="onFocus()"

      type="password"
      id="newPasswordDoubleCheck"
      class="form-control"
      placeholder="再次輸入新密碼"
      required
      minlength="4"
      maxlength="16"
      />

      <button
      class="btn btn--request"
      type="submit"
      [disabled]="!passwordForm.valid || state !== '儲存'"
      >
        <span *ngIf="isStoring" class="spinner-border spinner-border-sm"></span>
        {{ state }}
      </button>

    </form>

  </div>

</div>
