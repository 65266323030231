import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from 'src/environments/environment';
import { Role } from "src/app/models/role.module";
import { RoleService } from "src/app/shared/services/role.service";

@Injectable({ providedIn: 'root' })

export class EditPermissionService {
  role: Role[] = [];
  roleAuth: number = 0;

  permission: string = '';

  constructor(
    private http: HttpClient,
    private roleService: RoleService
    ) {}


  /**
   *
   */
  askAuth() {
    this.http
    .get(`${environment.SellsServer}/api/System/Role/Authorizations`)
    .subscribe({
      next: (response: any) => {
        this.fetchData(response as Role[]);
      },
      complete: () => {
        // console.log('role: ' + this.roleAuth);
        return this.roleAuth
      },
      error: (err: any) => {
        console.log(err);
      }
    })
  }

  fetchData(data: Role[]) {
    this.role = data;
  }


  getAuth() {
    // console.log(this.roleAuth);
    return this.roleAuth
  }

  /**
   * 存入已轉為二進制的 roleAuth
   * @param permission
   */
  setPermission(permission: string) {
    this.permission = permission;
  }

  /**
   * 取得二進制表示的 roleAuth
   * @returns
   */
  getPermission() {
    return this.permission
  }
}
