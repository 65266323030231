import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'splitTitle'
})

export class SplitTitlePipe implements PipeTransform {
  transform(value: any, position: number) {
    // ex： "營運管理__帳務資訊"，以 "__" 為分界，根據 :0 / :1 決定要前 or 後的字串

    let array = value.split("__"); // Output: ["營運控管","帳務資訊"]

    return array[position]; // {{ splitTitle:0 }}　Output: 營運控管
  }
}
