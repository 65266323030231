  <div class="row justify-content-center m-0 background">
    <div class="col-xl-3 col-lg-6 col-md-8">

      <div class="login">

        <div class="login__box">
          <h1 class="login__heading">Diamond Online 推廣系統</h1>
          <img src="assets/image/Diamondonline_personalbusiness_fudanlogo_200x200.png" alt="復旦電競logo"
            class="login__logo">
        </div>

        <form
        class="form"
        (ngSubmit)="onLogging()"
        #f="ngForm" ngNativeValidate>

          <p class="form__warning">{{ errorMessage }}</p>

          <input
          autofocus
          (focus)="onFocus()"
          type="text"
          id="username"
          class="form__input"
          placeholder=" 帳號"
          required
          minlength="4"
          maxlength="16"
          ngModel
          name="username"
          >
          <input
          (focus)="onFocus()"
          type="password"
          id="password"
          class="form__input"
          placeholder=" 密碼"
          required
          minlength="4"
          maxlength="16"
          ngModel
          name="password"
          >
          <button class="btn btn--login">登入</button>
        </form>

      </div>

      <section class="third">
        <p>OR</p>

        <div class="third__group">
          <img class="third__logo" src="assets/image/player11_facebooke_200x200.png" alt="facebook icon" (click)="loginFB()">
          <img class="third__logo" src="assets/image/player12_line_200x200.png" alt="line icon" (click)="loginLine()">
          <img class="third__logo" src="assets/image/player10_google_200x200.png" alt="google icon" (click)="loginGoogle()">
          <img class="third__logo" src="assets/image/player15_apple_120x120.png" alt="apple icon" (click)="loginApple()">
        </div>
      </section>

      <footer>
        <p> &copy; 2023 - 復旦電競有限公司</p>
      </footer>

    </div>
  </div>
